import {action, makeObservable, observable} from "mobx";
import {ApiModel} from "./ApiModel";
import {get} from "lodash";
import {APP_ENVIRONMENT} from "modules/constant";

type TResponse = Record<string, string>;

export class I18n extends ApiModel<TResponse> {
	@observable public data: Record<string, string> = {};

	constructor(protected readonly api: () => Promise<TResponse>) {
		super(api);
		makeObservable(this);
		void this.request();
	}

	@action protected performResponse(response: TResponse) {
		this.data = response;
	}

	t(path: string, args?: Record<string, string>) {
		return this.translate(path, args);
	}

	translate(path: string, args?: Record<string, string>) {
		const defaultValue = APP_ENVIRONMENT === "uat" ? path : "";
		const translate = String(get(this.data, path, defaultValue));

		if (args) {
			return translate.replace(/{{(\w+)}}/g, (_, replaceKey: string) =>
				get(args, replaceKey, "")
			);
		}

		return translate;
	}

	protected onApiError(err: Error): void {
		console.log(err);
	}
}
