import {differenceBy, identity, last, map, toPairs} from "lodash";
import {action, makeAutoObservable, observable, reaction} from "mobx";
import {
	Checksums,
	JSONCollection,
	Lockout,
	Player,
	Squad,
	TradesAlert,
} from "modules/stores/Models";

export class LiveScoringService {
	@observable private timerInterval?: ReturnType<typeof setInterval>;

	private _mapChecksumsToRequests: Record<string, () => Promise<void>> = {
		squads: this._squads.request.bind(this._squads),
		players: this._players.request.bind(this._players),
		trade_alerts: this._tradeAlerts.request.bind(this._tradeAlerts),
	};

	constructor(
		private _lockoutController: Lockout,
		private _players: JSONCollection<Player>,
		private _squads: JSONCollection<Squad>,
		private _tradeAlerts: TradesAlert,
		private _checksums: Checksums
	) {
		makeAutoObservable(this);

		reaction(
			() => _lockoutController.isLockout,
			(isLockout) => {
				this.stopChecksumPolling();

				if (isLockout) {
					this.runChecksumPolling();
				}
			},
			{fireImmediately: true}
		);

		reaction(
			() => _lockoutController.isDraftEnd,
			(isDraftEnd) => {
				if (isDraftEnd) {
					this.stopChecksumPolling();
				}
			},
			{fireImmediately: true}
		);
	}

	@action async requestJSONsOnChange() {
		const currentChecksum = this._checksums.data;
		await this._checksums.request();

		const requestsForChanges = differenceBy(
			toPairs(currentChecksum),
			toPairs(this._checksums.data),
			last
		)
			.map((diff) => this._mapChecksumsToRequests[diff[0]])
			.filter(identity);

		map(requestsForChanges, (request) => request());

		if (this._lockoutController.isDraftEnd) {
			this.stopChecksumPolling();
		}
	}

	@action runChecksumPolling() {
		this.timerInterval = setInterval(() => {
			void this.requestJSONsOnChange();
		}, 1000 * 15);
	}

	@action private stopChecksumPolling() {
		clearInterval(this.timerInterval!);
	}
}
