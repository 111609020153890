import {action, makeObservable, observable, runInAction} from "mobx";
import {LoadState} from "modules/constant";

export abstract class ApiModel<TAPIResponse> {
	@observable public apiState: LoadState = LoadState.IDLE;
	@observable public error?: string;

	protected abstract onApiError(err: Error): void;
	protected abstract performResponse(resp: TAPIResponse): void;

	protected constructor(
		/**
		 * Any type is required here as we don't know what exactly params will be here
		 */
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		protected readonly api: (...args: unknown[]) => Promise<TAPIResponse>
	) {
		makeObservable(this);
	}

	@action
	/**
	 * Any type is required here as we don't know what exactly params will be here
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async request(...args: unknown[]) {
		if (this.apiState === LoadState.Requested) {
			return;
		}

		this.apiState = LoadState.Requested;
		this.error = undefined;

		try {
			const response = await this.api(...args);

			runInAction(() => {
				this.performResponse(response);
				this.apiState = LoadState.Received;
			});
		} catch (err) {
			this.apiState = LoadState.IDLE;
			this.error = (err as Error).message;

			runInAction(() => {
				this.onApiError(err as Error);
			});
		}
	}
}
