import React, {useCallback} from "react";
import {Player} from "modules/stores/Models";
import {
	Exist,
	PureButton,
	SPlayerNameWrapper,
	SUserPlayIcon,
	TooltipContent,
} from "views/components";
import {useMediaQuery, useStore} from "modules/hooks";
import styled from "styled-components";
import plusIcon from "assets/img/icons/plus_primary.svg";
import {observer} from "mobx-react";
import playIcon from "assets/img/icons/play.png";
import ReactTooltip from "react-tooltip";

const SPlayerItem = styled.div`
	padding: 9px 24px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	min-height: 62px;
	box-sizing: border-box;

	&:nth-child(even) {
		background: #f8f8f8;
	}
`;

const SPlayerIndex = styled.span`
	color: #616161;
	font-size: 20px;
	font-weight: 600;
	letter-spacing: 0;
	margin-right: 32px;
	min-width: 42px;
`;

const SPlayerName = styled.p`
	color: #333333;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 20px;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
`;

const SPlayerPosition = styled.span`
	color: #757575;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0;
	display: inline-block;
	margin-right: 20px;
	text-transform: uppercase;
`;

const SPlayerActionButton = styled(PureButton)`
	margin-left: auto;
	display: flex;
	align-items: center;

	color: #1b48e0;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0;
`;

const SButtonIcon = styled.img`
	margin-left: 8px;
`;

interface IProps {
	player: Player;
	index: number;
	className: string;
}

const SPoolUserPlayIcon = styled(SUserPlayIcon)`
	left: -32px;
`;

export const PlayerItem: React.FC<IProps> = observer(({player, className}) => {
	const {
		gameManageController,
		overallPositions,
		lockoutController,
		I18n,
		playerModalManager,
	} = useStore();
	const {id, firstName, lastName, position, college, link} = player;
	const playerPosition = overallPositions.byShortName[position].full;
	const isDesktop = useMediaQuery("(min-width: 860px)");
	const {isLockout} = lockoutController;
	const isAddPlayersVisible =
		!isLockout && !gameManageController.isAllPositionsFilled;

	const addPlayer = useCallback(() => {
		gameManageController.addPlayer(id);
	}, [gameManageController, id]);

	const openModal = useCallback(() => {
		playerModalManager.openModal(id);
	}, [playerModalManager, id]);

	return (
		<SPlayerItem className={className}>
			<SPlayerIndex>{player.rank}.</SPlayerIndex>
			<SPlayerNameWrapper>
				<Exist when={Boolean(link)}>
					<SPoolUserPlayIcon
						onClick={openModal}
						src={playIcon}
						data-tip
						className={"step-3"}
						alt="play"
					/>
				</Exist>
				<SPlayerName onClick={openModal}>
					{firstName} {lastName}
				</SPlayerName>
				<SPlayerPosition>{playerPosition}</SPlayerPosition>
				<SPlayerPosition>{college}</SPlayerPosition>
			</SPlayerNameWrapper>
			<Exist when={isAddPlayersVisible}>
				<SPlayerActionButton onClick={addPlayer}>
					{isDesktop ? I18n.t("draft_page.add_player") : ""}
					<SButtonIcon src={plusIcon} alt="+" />
				</SPlayerActionButton>
			</Exist>
			{/*Typescript check disabled due to false warning*/}
			{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
			{/* @ts-ignore*/}
			<ReactTooltip
				delayHide={250}
				effect="solid"
				backgroundColor="#2f2ff9"
				place="bottom"
			>
				<TooltipContent />
			</ReactTooltip>
		</SPlayerItem>
	);
});
