import React, {useCallback, useRef, useState} from "react";
import {
	ModalBody,
	ModalContainer,
	ModalHeader,
	ModalOverlay,
} from "views/components/Modals/ModalBase";
import {CTAButtonUppercase} from "views/components/Button";
import styled from "styled-components";
import {Checkbox} from "views/components/Inputs";
import {observer} from "mobx-react";
import {useStore} from "modules/hooks";
import {Exist} from "views/components/Exist";
import {CircularLoader} from "views/components/Preloader";

const TermsModalBody = styled(ModalBody)`
	padding: 40px 100px;
`;

const TermsCtaButton = styled(CTAButtonUppercase)`
	padding-left: 19px;
	padding-right: 19px;
	margin-top: 14px;
	width: 100%;
	transition: all 0.3s;

	&:disabled {
		background: gray;
		border-color: gray;
	}
`;

const FormControlWrapper = styled.form`
	p {
		color: #757575;
		font-size: 12px;
		font-weight: 300;
		letter-spacing: 0;
		line-height: 16px;
		text-align: left;
		margin-top: 0;
		margin-bottom: 4px;
	}

	b {
		font-weight: bold;
	}
`;

const Loader = styled(CircularLoader)`
	position: relative;
	top: 2px;

	circle {
		stroke: #ffffff;
	}
`;

interface ITermsFormElement extends HTMLFormElement {
	terms: HTMLInputElement;
}

export const RecoverModal: React.FC = observer(() => {
	const {authController, I18n} = useStore();
	const formRef = useRef<ITermsFormElement>(null);
	const [isFormValid, setFormValid] = useState(false);

	const isInProgressState = authController.isCreateAccountProcessing;
	const isSubmitButtonDisabled = !isFormValid || isInProgressState;

	const onSubmit = useCallback(
		(event: React.SyntheticEvent<ITermsFormElement>) => {
			event.preventDefault();
			const form = event.currentTarget;

			if (form.checkValidity()) {
				void authController.APIRecoverUser();
			}
		},
		[authController]
	);

	const validateForm = useCallback(
		(event: React.SyntheticEvent<HTMLFormElement>) => {
			setFormValid(event.currentTarget.checkValidity());
		},
		[]
	);

	if (!authController.isRecoveredModalVisible) {
		return null;
	}

	return (
		<ModalOverlay>
			<ModalContainer showClose={false}>
				<ModalHeader title={I18n.t("modals.terms.title")} />
				<TermsModalBody>
					<FormControlWrapper
						noValidate={true}
						ref={formRef}
						onChange={validateForm}
						onSubmit={onSubmit}
					>
						<div>
							<Checkbox
								name="terms"
								required={true}
								disabled={isInProgressState}
							>
								{I18n.t("modals.terms.agree_terms")}*
							</Checkbox>
						</div>
						<TermsCtaButton
							type="submit"
							disabled={isSubmitButtonDisabled}
						>
							<Exist when={isInProgressState}>
								<Loader />
							</Exist>
							<Exist when={!isInProgressState}>
								{I18n.t("modals.terms.submit")}
							</Exist>
						</TermsCtaButton>
					</FormControlWrapper>
				</TermsModalBody>
			</ModalContainer>
		</ModalOverlay>
	);
});
