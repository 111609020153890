import {
	action,
	computed,
	makeAutoObservable,
	observable,
	runInAction,
} from "mobx";
import {Api, ApiError, ILeaderBoardRequestParams} from "modules/utils";
import {Order} from "modules/constant";
import {ErrorManager} from "modules/stores/Controllers/ErrorManager";

export interface ILeaderboardItem {
	id: number;
	rank: number | null;
	score: number | null;
	username: string;
}

interface ILeaderboardResult {
	result: {
		leaderboard: ILeaderboardItem[];
		nextPage: boolean;
		userStanding: {
			id: number;
			username: string;
			rank: number | null;
			score: number | null;
		};
	};
}

export class LeaderBoardManager {
	@observable private timerInterval?: ReturnType<typeof setInterval>;
	@observable public entities: ILeaderboardItem[] = [];
	@observable public currentUser: ILeaderboardItem | null = null;
	@observable public hasNext: boolean = true;

	private readonly MAX_LIMIT = 500;
	private readonly limit = 25;
	@observable private order = Order.ASC;

	@computed get entitiesSize() {
		return this.entities.length;
	}

	@computed get params() {
		return {
			offset: this.entitiesSize,
			limit: this.limit,
			order: this.order,
		};
	}

	constructor(private errorManager: ErrorManager) {
		makeAutoObservable(this);
	}

	@action
	public runLivePolling() {
		this.stopLivePolling();

		this.timerInterval = setInterval(() => {
			this.fetchLeaderboards({
				offset: 0,
				limit: this.entitiesSize,
			});
		}, 1000 * 60);
	}

	@action
	public stopLivePolling() {
		clearInterval(this.timerInterval!);
	}

	@action
	public fetchLeaderboards = (
		params?: Partial<ILeaderBoardRequestParams>
	) => {
		void Api.LeaderBoard.leaderBoards<ILeaderboardResult>({
			...this.params,
			...params,
		})
			.then((response) => {
				const {
					result: {nextPage, leaderboard, userStanding},
				} = response;

				runInAction(() => {
					this.entities = leaderboard;
					this.currentUser = userStanding;
					this.hasNext =
						this.entitiesSize >= this.MAX_LIMIT ? false : nextPage;
				});
			})
			.catch(this.handleError);
	};

	@action
	public loadMoreLeaderboards = () => {
		this.fetchLeaderboards({
			offset: 0,
			limit: this.params.offset + this.limit,
		});
	};

	@action
	public clearLeaderboard() {
		this.entities = [];
		this.currentUser = null;
	}

	@action
	public toggleSort() {
		this.order = this.isOrderEqual(Order.ASC) ? Order.DESC : Order.ASC;
		this.fetchLeaderboards({offset: 0});
	}

	@computed
	public isOrderEqual(order: Order) {
		return this.order === order;
	}

	private handleError = (response: ApiError) => {
		this.errorManager.setError(response.message);
	};
}
