import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";

import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import {StoreProvider, RootStore} from "modules/stores";
import {Footer, Header, PagePreloader} from "views/components";
import styled from "styled-components";
import {Routes} from "routes";
import {configure} from "mobx";

// don't allow state modifications outside actions
configure({
	enforceActions: "always",
	computedRequiresReaction: true,
	reactionRequiresObservable: true,
});

Sentry.init({
	dsn:
		"https://7e3d68e2b59741a9adf02565cdff5a2e@o151969.ingest.sentry.io/5673201",
	integrations: [
		new Integrations.BrowserTracing(),
		new Sentry.Integrations.Breadcrumbs({
			console: false,
		}),
	],
	environment: process.env.REACT_APP_SENTRY_ENV || "development",
	allowUrls: [".fanhubmedia.com", "nfldraftpredictor.co.uk"],
	denyUrls: [
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
	],
	tracesSampleRate: 0.1,
});

const Main = styled.div`
	flex: 1;
`;

const rootStore = new RootStore();

ReactDOM.render(
	<StoreProvider store={rootStore}>
		<Router>
			<Header />
			<Main>
				<Suspense fallback={<PagePreloader />}>
					<Routes />
				</Suspense>
			</Main>
			<Footer />
		</Router>
	</StoreProvider>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
