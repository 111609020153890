import React, {useCallback} from "react";
import styled from "styled-components";
import dragUpIcon from "assets/img/icons/drag-up.svg";
import dragDownIcon from "assets/img/icons/drag-down.svg";
import dragCenterIcon from "assets/img/icons/drag-drag.svg";
import lockIcon from "assets/img/icons/lock.svg";
import {PureButton} from "views/components/Button";
import {observer} from "mobx-react";
import {useMediaQuery, useStore} from "modules/hooks";

const SDragWrapper = styled.div`
	width: 28px;
	height: 63px;
	box-sizing: border-box;
	padding: 11px 9px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin-right: 4px;

	img {
		pointer-events: none;
	}
`;

const CenterPureButton = styled(PureButton)`
	padding-top: 4px;
	cursor: grab;

	&.invisible {
		opacity: 0;
		pointer-events: none;
	}
`;

const SDragWrapperEmpty = styled(SDragWrapper)`
	opacity: 0;
	pointer-events: none;
`;

const LockedField = styled(SDragWrapper)`
	background: url(${lockIcon}) no-repeat center center;
	pointer-events: none;
`;

const SDrag = styled.div`
	background-image: url(${dragCenterIcon});
	width: 6px;
	height: 12px;
	display: inline-block;
`;

interface IProps {
	hasPlayer: boolean;
	index: number;
	onMouseDown?: () => void;
}

export const DragItem: React.FC<IProps> = observer(
	({hasPlayer, index, onMouseDown}) => {
		const isDesktop = useMediaQuery("(min-width: 768px)");

		const {
			lockoutController: {isLockout},
			gameManageController,
		} = useStore();

		const moveDown = useCallback(() => {
			if (index === 15) {
				return;
			}
			gameManageController.movePlayer(index, index + 1);
		}, [gameManageController, index]);

		const moveUp = useCallback(() => {
			if (index === 1) {
				return;
			}
			gameManageController.movePlayer(index, index - 1);
		}, [gameManageController, index]);

		if (isLockout) {
			return <LockedField />;
		}

		if (!hasPlayer) {
			return <SDragWrapperEmpty />;
		}

		return (
			<SDragWrapper>
				<PureButton onClick={moveUp}>
					<img src={dragUpIcon} alt="up" />
				</PureButton>
				<CenterPureButton
					className={isDesktop ? "" : "invisible"}
					onMouseDown={onMouseDown}
					onTouchStart={onMouseDown}
				>
					<SDrag />
				</CenterPureButton>
				<PureButton onClick={moveDown}>
					<img src={dragDownIcon} alt="down" />
				</PureButton>
			</SDragWrapper>
		);
	}
);
