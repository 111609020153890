import styled from "styled-components";
import {useStore} from "modules/hooks";
import {SQUAD_IMAGE} from "modules/constant";
import React from "react";
import {observer} from "mobx-react";

const SquadFigure = styled.figure`
	width: 40px;
	min-height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const SquadLogoImage = styled.img`
	max-width: 40px;
	height: auto;
	width: 100%;
`;

interface IProps {
	squadID?: number;
}

const handleOnError = (event: React.SyntheticEvent<HTMLImageElement>) =>
	(event.currentTarget.style.display = "none");

export const SquadLogo: React.FC<IProps> = observer(({squadID}) => {
	const {squads} = useStore();
	const squad = squads.byID[squadID!];

	if (!squad) {
		return <SquadFigure />;
	}

	return (
		<SquadFigure>
			<SquadLogoImage
				onError={handleOnError}
				src={SQUAD_IMAGE.get(squadID)}
				alt={squad.name}
			/>
		</SquadFigure>
	);
});
