import {action, computed, makeAutoObservable, observable} from "mobx";
import {LoadState} from "modules/constant";
import {Api, ApiError} from "modules/utils";
import {ErrorManager} from "modules/stores/Controllers/ErrorManager";
import {isEqual} from "lodash";

export interface IContact {
	name: string;
	email: string;
	category: string;
	question: string;
}

export class Contact {
	@observable private contactApiState = LoadState.IDLE;

	constructor(private errorManager: ErrorManager) {
		makeAutoObservable(this);
	}
	@action setApiState(state: LoadState) {
		this.contactApiState = state;
	}

	@computed public get isContactProcessing() {
		return isEqual(this.contactApiState, LoadState.Requested);
	}

	@computed public get isContactSubmitted() {
		return isEqual(this.contactApiState, LoadState.Received);
	}

	@action private checkResponse = () => {
		this.setApiState(LoadState.Received);
		setTimeout(() => {
			this.setApiState(LoadState.IDLE);
		}, 10000);
	};

	@action public contact = (args: IContact) => {
		this.setApiState(LoadState.Requested);

		return Api.Contact.contact<IContact>({
			...args,
		})
			.then(this.checkResponse)
			.catch(this.handleNetworkError);
	};

	private handleNetworkError = (response: ApiError) => {
		this.setApiState(LoadState.Received);
		this.errorManager.setError(response.message);
	};
}
