import React, {lazy} from "react";
import {Route, Switch, useHistory} from "react-router-dom";
import {useStore} from "modules/hooks";
import {observer} from "mobx-react";
import {GigyaGate} from "views/components";
import {
	ErrorsModal,
	TermsConditionsModal,
	WelcomeModal,
	ShareModal,
	PlayerModal,
	ConfirmationModal,
	RecoverModal,
} from "views/components/Modals";

const Home = lazy(() => import("views/pages/Home"));
const ComingSoon = lazy(() => import("views/pages/ComingSoon"));
const Leaderboard = lazy(() => import("views/pages/Leaderboard"));
const Help = lazy(() => import("views/pages/Help"));
const NotFound = lazy(() => import("views/pages/NotFound"));

export const Routes: React.FC = observer(() => {
	const history = useHistory();
	const {comingSoonController} = useStore();

	if (comingSoonController.isComingSoonEnabled) {
		return (
			<Switch>
				<Route path="/help/*" component={Help} />
				<Route exact path="/" component={ComingSoon} />
			</Switch>
		);
	}

	if (history.location.search.includes("gig_actions")) {
		history.push(history.location.pathname);
	}

	return (
		<GigyaGate>
			<Switch>
				<Route exact={true} path="/" component={Home} />
				<Route
					exact={true}
					path="/leaderboard"
					component={Leaderboard}
				/>
				<Route path="/help" component={Help} />
				<Route path="*" component={NotFound} />
			</Switch>
			<ErrorsModal />
			<WelcomeModal />
			<TermsConditionsModal />
			<RecoverModal />
			<ShareModal />
			<PlayerModal />
			<ConfirmationModal />
		</GigyaGate>
	);
});
