import React from "react";
import {
	ModalBody,
	ModalBodyText,
	ModalContainer,
	ModalHeader,
	ModalOverlay,
} from "views/components/Modals/ModalBase";
import {CTAButton} from "views/components/Button";
import {useStore} from "modules/hooks";
import {observer} from "mobx-react";

export const ErrorsModal: React.FC = observer(() => {
	const {errorManagerController, I18n} = useStore();

	if (!errorManagerController.error) {
		return null;
	}

	return (
		<React.Fragment>
			<ModalOverlay onClick={errorManagerController.clearError} />
			<ModalContainer
				showClose={true}
				onCloseClick={errorManagerController.clearError}
			>
				<ModalHeader title={I18n.t("modals.errors.error")} />
				<ModalBody>
					<ModalBodyText>
						{errorManagerController.error}
					</ModalBodyText>
					<CTAButton onClick={errorManagerController.clearError}>
						{I18n.t("modals.errors.close")}
					</CTAButton>
				</ModalBody>
			</ModalContainer>
		</React.Fragment>
	);
});
