import {action, makeAutoObservable, observable} from "mobx";

export class ConfirmationManager {
	@observable public isModalOpen: boolean = false;
	@observable public location: string | null = null;
	@observable public blockPrompt: boolean = true;
	@observable public isForceRedirect: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	@action
	public openModal(location: string): void {
		this.isModalOpen = true;
		this.blockPrompt = true;
		this.location = location;
	}

	@action
	public closeModal(isForceRedirect: boolean): void {
		this.isForceRedirect = isForceRedirect;
		this.isModalOpen = false;
		console.log(this.isForceRedirect);
	}

	@action
	public clearLocation() {
		this.location = null;
	}
}
