import styled from "styled-components";

export const ModalBody = styled.div`
	padding: 40px 30px;
	color: #555555;
	text-align: center;
	overflow: auto;

	@media screen and (max-width: 425px) {
		padding: 40px 24px !important;
	}
`;
