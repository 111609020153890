import styled from "styled-components";
import React from "react";
import {ANSWER_SCORES} from "modules/constant";
import {useStore} from "modules/hooks";

interface IScoresContainerProps {
	isIncorrect: boolean;
}

const ScoresContainer = styled.div`
	font-size: 10px;
	width: 70px;
	min-width: 70px;
	margin-left: auto;
	margin-right: 0;

	p {
		text-transform: uppercase;
		color: #616161;
		font-weight: bold;
		margin-bottom: 5px;
	}

	div {
		background: ${({isIncorrect}: IScoresContainerProps) =>
			isIncorrect ? "#B62523" : "#5EAD00"};
		width: 60px;
		color: #fff;
		text-align: center;
		border-radius: 2px;
		padding: 3px 0;
	}
`;

interface IPointsProps {
	points: number | null;
}

const ScoreText: React.FC<IPointsProps> = ({points}) => {
	const {I18n} = useStore();
	if (points === ANSWER_SCORES.ACCURATE) {
		return <p>{I18n.t("draft_page.results.correct")}</p>;
	}

	if (points === ANSWER_SCORES.ONE_PICK_AWAY) {
		return <p>{I18n.t("draft_page.results.one_away")}</p>;
	}

	if (points === ANSWER_SCORES.TWO_PICKS_AWAY) {
		return <p>{I18n.t("draft_page.results.two_away")}</p>;
	}
	return <p>{I18n.t("draft_page.results.incorrect")}</p>;
};

export const Points: React.FC<IPointsProps> = ({points}) => {
	if (points === null) {
		return null;
	}

	const isIncorrect = points === 0;

	return (
		<ScoresContainer isIncorrect={isIncorrect}>
			<ScoreText points={points} />
			<div>{points} PTS</div>
		</ScoresContainer>
	);
};
