import styled from "styled-components";
import headerBg from "assets/img/main-bg-big-2023.jpg";
import logo from "assets/img/logo-white-2023.svg";
import adLogo from "assets/img/coreHeaderAd.png";
import ukSponsor from "assets/img/888sport_white_IMAGE.png";
import sponsorDELogo from "assets/img/de_sponsor_logo_white.png";
import React from "react";
import {BroadCasters, Container, Exist, LiveDraft} from "views/components";
import {useMediaQuery, useStore} from "modules/hooks";
import {observer} from "mobx-react";
import ballImg from "assets/img/ballImg.png";

const mobileBreakpoint = "576px";

const Wrapper = styled.div`
	position: relative;
	box-sizing: border-box;
	background: url(${headerBg}) no-repeat 50% 50%;
	background-size: cover;
	padding: 32px 30px;
	height: 180px;
	display: flex;
	align-items: center;

	&:after {
		content: "";
		height: 180px;
		width: 400px;
		position: absolute;
		right: 0;
		bottom: 0;
		background: url(${ballImg}) no-repeat center;

		@media screen and (max-width: 1010px) {
			display: none;
		}
	}

	@media screen and (max-width: 1010px) {
		padding: 24px;
	}

	@media screen and (max-width: 576px) {
		padding: 0;
		height: 130px;
	}
`;

const InnerWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;

	@media screen and (max-width: 576px) {
		padding: 0;
		flex-direction: column;
		height: 110px;
	}
`;

const LogosWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;

	@media screen and (max-width: ${mobileBreakpoint}) {
		justify-content: center;
		align-items: center;
		padding: 5px 0;
	}
`;

const HeaderLogo = styled.img`
	width: auto;
	height: 100px;
	margin-right: 10px;

	@media screen and (max-width: ${mobileBreakpoint}) {
		width: auto;
		height: 60px;
		margin: 0 15px;
	}
`;

const SportLogo = styled.a`
	padding: 8px;
	width: 190px;
	height: 80px;
	align-self: center;
	background-image: url(${adLogo});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;

	@media screen and (max-width: ${mobileBreakpoint}) {
		width: 80px;
		height: 31px;
		margin: 0 15px;
		background-position: 50%;
	}
`;

const SportUKLogo = styled.a`
	padding: 8px;
	width: 80px;
	height: 80px;
	align-self: center;
	background-image: url(${ukSponsor});
	background-size: contain;
	background-repeat: no-repeat;
	margin-left: 24px;
	margin-right: 12px;

	@media screen and (max-width: ${mobileBreakpoint}) {
		width: 40px;
		height: 40px;
		margin: 0 15px;
		//background-position: 50%;
	}
`;

const SportDELogo = styled.div`
	padding: 8px;
	width: 80px;
	height: 80px;
	align-self: center;
	background-image: url(${sponsorDELogo});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	margin-left: 24px;
	margin-right: 12px;

	@media screen and (max-width: ${mobileBreakpoint}) {
		width: 40px;
		height: 40px;
		margin: 0 15px;
	}
`;

const SubHeaderText = styled.h2`
	color: #ffffff;
	font-size: 40px;
	font-weight: bold;
	letter-spacing: 1px;
	line-height: 40px;
	max-width: 712px;
	padding: 0 24px;

	@media screen and (max-width: 1320px) {
		font-size: 32px;
	}

	@media screen and (max-width: 960px) {
		display: none;
	}
`;

const SubheaderSubText = styled(SubHeaderText)`
	font-size: 16px;
	line-height: 16px;
	margin-top: 8px;
	@media screen and (max-width: 1240px) {
		display: none;
	}
`;

const SubHeaderContainer = styled(Container)`
	padding: 0;
	@media screen and (max-width: 1000px) {
		padding: 0 !important;
	}
`;

export const SubHeader: React.FC = observer(() => {
	const {I18n, SEOManager} = useStore();
	const isShow = true;
	const isDesktop = useMediaQuery("(min-width: 600px)");

	if (!isShow) {
		return null;
	}

	return (
		<Wrapper>
			<SubHeaderContainer>
				<InnerWrapper>
					<LogosWrapper>
						<HeaderLogo src={logo} alt="logo" />
						<Exist when={SEOManager.isShowEightLogo}>
							<SportLogo href={SEOManager.getAdLink} />
						</Exist>

						<Exist when={SEOManager.isShowUKSponsor}>
							<SportUKLogo href="https://www.888sport.com" />
						</Exist>

						<Exist when={SEOManager.isShowDESponsor}>
							<SportDELogo />
						</Exist>
					</LogosWrapper>
					<div>
						<SubHeaderText>
							{I18n.t("subheader.title")}
						</SubHeaderText>
						<SubheaderSubText>
							{I18n.t("subheader.subtitle")}
						</SubheaderSubText>
					</div>
					<Exist when={isDesktop}>
						<BroadCasters />
					</Exist>

					<LiveDraft />
				</InnerWrapper>
			</SubHeaderContainer>
		</Wrapper>
	);
});
