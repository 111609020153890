import {identity, get} from "lodash";
import {FB_SHARE_URL, SYNDICATE} from "modules/constant";
import {ConnextraType, createConnextraScriptTag} from "modules/utils";

interface IShareObject {
	t?: string;
	squadId: number;
	lang: string;
}

interface IShareUserData {
	userId: number;
	t?: string;
}

/**
 * in order to reduce the size of the string for sharing
 */
const last_four_number = -4;
const getTimestamp = () => Date.now().toString().substr(last_four_number);

const getEncodedJson = (object: IShareObject | IShareUserData) =>
	btoa(JSON.stringify(object));

const getLinkForShare = (params: IShareObject | IShareUserData) => {
	return `${FB_SHARE_URL}${SYNDICATE}/${getEncodedJson(params)}`;
};

export const shareFB = (params: IShareObject | IShareUserData) => {
	const FB_API = window.FB;

	const userId = get(params, "userId", null) as number | null;
	if (userId) {
		createConnextraScriptTag(ConnextraType.SHARE_CLICK, userId);
	}

	if (FB_API) {
		params.t = getTimestamp();

		FB_API.ui(
			{
				method: "share",
				display: "popup",
				href: getLinkForShare(params),
			},
			identity
		);
	}
};

export const shareTwitter = (params: IShareUserData, copy: string) => {
	params.t = getTimestamp();
	const href = getLinkForShare(params);

	window.open(
		"https://twitter.com/share?url=" +
			encodeURIComponent(href) +
			"&text=" +
			encodeURIComponent(copy),
		"twitter-share-dialog",
		"width=626,height=436"
	);
};
