import styled from "styled-components";

export const PureButton = styled.button`
	border: 0;
	padding: 0;
	margin: 0;
	background: none;
	cursor: pointer;
`;

export const CTAButton = styled.button`
	color: #ffffff;
	border: 1px solid #1b48e0;
	border-radius: 4px;
	background-color: #1b48e0;
	padding: 11px 40px;
	cursor: pointer;
	font-weight: bold;
	width: 100%;
	height: 44px;
	line-height: 118%;

	@media (max-width: 1000px) {
		padding: 10px;
	}

	&:disabled {
		background: gray;
		border-color: gray;
		cursor: default;
		pointer-events: none;
	}
`;

export const CTAButtonUppercase = styled(CTAButton)`
	text-transform: uppercase;
`;
