import styled from "styled-components";
// import titleImage from "assets/img/modal_banner.jpg";
import titleImage from "assets/img/main-bg-big-2023-1.jpg";
import React from "react";

export const ModalHeaderStyled = styled.div`
	box-sizing: border-box;
	background: url(${titleImage}) no-repeat 50% 50%;
	background-size: cover;
	width: 100%;
	min-height: 260px;
	-webkit-border-radius: 4px 4px 0 0;
	-moz-border-radius: 4px 4px 0 0;
	border-radius: 4px 4px 0 0;
	text-align: center;
	padding: 20px 24px;
`;

export const ModalHeaderTitle = styled.h3`
	color: #ffffff;
	font-family: "AllProDisaplayB", sans-serif;
	font-size: 30px;
	font-weight: bold;
	letter-spacing: 1px;
	text-align: center;
	margin-top: -14px;
`;

// const LogoImage = styled.img`
// 	width: 108px;
// 	height: 100px;
// 	position: relative;
// 	top: -56px;
// `;

interface IProps {
	title?: string;
}

export const ModalHeader: React.FC<IProps> = ({title, children}) => {
	return (
		<ModalHeaderStyled>
			{/*<LogoImage src={logo} alt="logo" />*/}
			<ModalHeaderTitle>{title}</ModalHeaderTitle>
			{children}
		</ModalHeaderStyled>
	);
};
