const BASE = "";
const VERSION = process.env.REACT_APP_STORAGE_VERSION || "";

/**
 * Wrapper for sessionStorage that made storage key uniq for any base path.
 * It's required for multiple projects on the same domain but at different folders
 */
export abstract class SessionStorage {
	protected static storage: Storage = sessionStorage;
	/**
	 * Store key/value data in localStorage
	 */
	public static SET(key: string, value: unknown): void {
		const val =
			typeof value === "string" ? value : JSON.stringify(value || "");
		this.storage.setItem(this.generate_key(key), val);
	}

	/**
	 * Get value by key from localStorage
	 */
	public static GET(key: string) {
		return this.storage.getItem(this.generate_key(key));
	}

	/**
	 * Remove value by key from localStorage
	 */
	public static REMOVE(key: string): void {
		this.storage.removeItem(this.generate_key(key));
	}

	/**
	 * Remove all values from localStorage
	 */
	public static FLUSH(): void {
		this.storage.clear();
	}

	private static generate_key(key: string): string {
		return BASE + key + "-" + VERSION;
	}
}

export default SessionStorage;
