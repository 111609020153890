import React from "react";
import {
	ModalBody,
	ModalBodyHeading,
	ModalBodyText,
	ModalContainer,
	ModalHeader,
	ModalHeaderStyled,
	ModalOverlay,
} from "views/components/Modals/ModalBase";
import styled from "styled-components";
import {CTAButton, PureButton} from "views/components/Button";
import {observer} from "mobx-react";
import {useStore} from "modules/hooks";
import {Exist} from "views/components/Exist";
import ukSponsor from "assets/img/888logos_HORIZONTAL_Orange.png";

const CustomModalHeader = styled.div<{isMx?: boolean}>`
	h3 {
		font-size: ${(props) =>
			props.isMx ? "24px !important" : "30px !important"};
	}
`;

const ModalFooterAnnotation = styled.p`
	color: #151515;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	margin-top: 30px;
	margin-bottom: 0;
`;

const FooterAnnotationLink = styled(PureButton)`
	text-decoration: underline;
	font-size: 16px;
	color: #005dbe;
	font-weight: bold;
`;

const WelcomeHeader = styled.div`
	${ModalHeaderStyled} {
		padding-left: 64px;
		padding-right: 64px;
		@media screen and (max-width: 450px) {
			padding-left: 18px;
			padding-right: 18px;
		}
	}
`;

const SponsorUK = styled.a`
	width: 50%;
	img {
		width: auto;
		max-width: 100%;
		height: 70px;
	}
`;

export const WelcomeModal: React.FC = observer(() => {
	const {authController, I18n, SEOManager} = useStore();

	if (!authController.isWelcomeModalVisible) {
		return null;
	}

	return (
		<React.Fragment>
			<ModalOverlay onClick={authController.hideWelcomeModal} />
			<ModalContainer
				showClose={true}
				onCloseClick={authController.hideWelcomeModal}
			>
				<WelcomeHeader>
					<CustomModalHeader isMx={SEOManager.isSyndicate("mx")}>
						<ModalHeader />
					</CustomModalHeader>
				</WelcomeHeader>
				<ModalBody>
					<Exist when={SEOManager.isSyndicate("uk")}>
						<SponsorUK href="https://www.888sport.com">
							<img src={ukSponsor} alt="888sport" />
						</SponsorUK>
					</Exist>
					<ModalBodyHeading>
						{I18n.t("modals.welcome.play")}
					</ModalBodyHeading>
					<ModalBodyText>
						{I18n.t("modals.welcome.eye_talent")}
					</ModalBodyText>
					<CTAButton onClick={authController.hideWelcomeModal}>
						{I18n.t("modals.welcome.start_drafting")}
					</CTAButton>
					<ModalFooterAnnotation>
						{I18n.t("modals.welcome.have_picks_q")}&nbsp;
						<FooterAnnotationLink onClick={authController.SSOLogin}>
							{I18n.t("forms.login")}
						</FooterAnnotationLink>
					</ModalFooterAnnotation>
				</ModalBody>
			</ModalContainer>
		</React.Fragment>
	);
});
