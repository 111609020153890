import styled from "styled-components";

export const ModalBodyHeading = styled.h4`
	color: #333333;
	font-style: italic;
	font-weight: 900;
	font-size: 24px;
	line-height: 120%;
	text-align: center;
	margin-bottom: 1.5rem;
`;
