import React, {Fragment, useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {Container} from "views/components/Container";
import logo from "assets/img/logo.svg";
import sponsorLogo from "assets/img/sponsor_small_logo.svg";
import {NavLink, useLocation} from "react-router-dom";
import {Exist} from "views/components/Exist";
import {useMediaQuery, useStore} from "modules/hooks";
import {ExternalLink} from "views/components/Link";
import {observer} from "mobx-react";
import {PureButton} from "views/components/Button";
import {HamburgerButton} from "views/components/HamburgerButton";
import {CircularLoader} from "views/components/Preloader";
import {IS_REGISTRATION_DISABLED} from "modules/constant";

const Wrapper = styled.header`
	background: #25202d;
	position: relative;
	z-index: 10;
`;

const Inner = styled.div`
	padding: 5px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const PresentedBy = styled(ExternalLink)`
	display: flex;
	align-items: center;
	margin-right: 20px;

	p {
		font-size: 12px;
		margin: 0 10px 0 0;
	}
`;

const DesktopNavigation = styled.nav`
	display: flex;
	align-items: center;
	flex: 1;
`;

const MainNavItem = styled(NavLink)`
	color: #fff;
	font-size: 14px;
	line-height: 118%;
	margin-left: 20px;
	transition: background 0.3s;
	text-transform: uppercase;
	font-weight: bold;
	padding: 5px 10px;

	&.active {
		background: #e60048;
		color: #26212d;
	}
`;

const AccountButton = styled(PureButton)`
	color: #fff;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: bold;
	transition: opacity 0.3s;

	&:hover {
		opacity: 0.7;
	}
`;

const MobileMenu = styled.div`
	background: #1b48e0;
	position: fixed;
	height: 100%;
	width: 320px;
	box-sizing: border-box;
	padding: 80px 0 20px;
	left: -100%;
	top: 0;
	transition: left 0.5s;
	z-index: 9;

	&.active {
		left: 0;
	}
`;

const MobileMenuInner = styled.div`
	height: 100%;
	overflow: auto;
	display: flex;
	flex-flow: column;
	align-items: flex-start;

	${MainNavItem},
	${AccountButton} {
		padding: 15px 20px;
		opacity: 1;
		display: block;
		width: 100%;
		text-align: left;
		margin: 0;
		box-sizing: border-box;

		&.active {
			background: #1a3c58;
		}
	}
`;

const MobileMenuOverlay = styled.div`
	background-color: rgba(0, 0, 0, 0);
	position: fixed;
	height: 100%;
	width: 100%;
	left: -100%;
	transition: background-color 0.5s;
	z-index: 8;

	&.active {
		left: 0;
		background-color: rgba(0, 0, 0, 0.75);
	}
`;

export const Header: React.FC = observer(() => {
	const {
		authController: {
			isLoggedIn,
			isLogoutProcessing,
			SSOLogout,
			SSOLogin,
			isSessionChecked,
		},
		comingSoonController,
		I18n,
	} = useStore();
	const title = I18n.t("header.draft_predictor");
	const location = useLocation();
	const isDesktop = useMediaQuery("(min-width: 768px)");
	const [isMenuOpened, setMenuOpened] = useState(false);
	const menuClassName = isMenuOpened ? "active" : "";

	const toggleMenu = useCallback(() => {
		setMenuOpened(!isMenuOpened);
	}, [isMenuOpened]);

	useEffect(() => {
		setMenuOpened(false);
	}, [location]);

	return (
		<Fragment>
			<Wrapper>
				<Container>
					<Inner>
						<DesktopNavigation>
							<NavLink to="/">
								<img src={logo} alt="NFL" />
							</NavLink>
							<Exist when={!isDesktop}>
								<MainNavItem className="active" as="div">
									{title}
								</MainNavItem>
							</Exist>
							<Exist when={isDesktop}>
								<MainNavItem exact={true} to="/">
									{title}
								</MainNavItem>
								<Exist when={isLoggedIn}>
									<MainNavItem to="/leaderboard">
										{I18n.t("header.leaderboard")}
									</MainNavItem>
								</Exist>

								<Exist
									when={
										!comingSoonController._isComingSoonEnabled
									}
								>
									<MainNavItem to="/help">
										{I18n.t("header.help")}
									</MainNavItem>
								</Exist>
							</Exist>
						</DesktopNavigation>
						<Exist when={isDesktop}>
							<Exist when={false}>
								<PresentedBy href="https://www.888sport.com">
									<p>{I18n.t("coming_soon.presented_by")}</p>
									<img src={sponsorLogo} alt="888 sport" />
								</PresentedBy>
							</Exist>
							<Exist when={!IS_REGISTRATION_DISABLED}>
								<Exist when={isSessionChecked}>
									<Exist when={isLoggedIn}>
										<Exist when={!isLogoutProcessing}>
											<AccountButton onClick={SSOLogout}>
												{I18n.t("forms.logout")}
											</AccountButton>
										</Exist>
										<Exist when={isLogoutProcessing}>
											<CircularLoader />
										</Exist>
									</Exist>
									<Exist when={!isLoggedIn}>
										<AccountButton onClick={SSOLogin}>
											{I18n.t("forms.login")}
										</AccountButton>
									</Exist>
								</Exist>
							</Exist>
						</Exist>
						<Exist
							when={
								!isDesktop &&
								!comingSoonController.isComingSoonEnabled
							}
						>
							<HamburgerButton
								onClick={toggleMenu}
								className={menuClassName}
							/>
						</Exist>
					</Inner>
				</Container>
			</Wrapper>
			<MobileMenu className={menuClassName}>
				<MobileMenuInner>
					<MainNavItem exact={true} to="/">
						{title}
					</MainNavItem>
					<Exist when={isLoggedIn}>
						<MainNavItem to="/leaderboard">
							{I18n.t("header.leaderboard")}
						</MainNavItem>
					</Exist>
					<MainNavItem to="/help">
						{I18n.t("header.help")}
					</MainNavItem>
					<Exist when={!IS_REGISTRATION_DISABLED}>
						<Exist when={isLoggedIn}>
							<Exist when={!isLogoutProcessing}>
								<AccountButton onClick={SSOLogout}>
									{I18n.t("forms.logout")}
								</AccountButton>
							</Exist>
							<Exist when={isLogoutProcessing}>
								<CircularLoader />
							</Exist>
						</Exist>
						<Exist when={!isLoggedIn}>
							<AccountButton onClick={SSOLogin}>
								{I18n.t("forms.login")}
							</AccountButton>
						</Exist>
					</Exist>
				</MobileMenuInner>
			</MobileMenu>
			<MobileMenuOverlay onClick={toggleMenu} className={menuClassName} />
		</Fragment>
	);
});
