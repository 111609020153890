import {keyBy} from "lodash";
import {computed} from "mobx";

export class PoolPositions {
	private _positions = [
		{
			short: "QB",
			title: "Quarterbacks",
			groups: ["QB"],
		},
		{
			short: "RB",
			title: "Running Backs",
			groups: ["RB"],
		},
		{
			short: "WR",
			title: "Wide Receivers",
			groups: ["WR"],
		},
		{
			short: "TE",
			title: "Tight Ends",
			groups: ["TE"],
		},
		{
			short: "OL",
			title: "Offensive Line",
			groups: ["OL", "IOL", "OT"],
		},
		{
			short: "DL",
			title: "Defensive Line",
			groups: ["DL", "IDL", "EDGE", "DE", "DT"],
		},
		{
			short: "LB",
			title: "Linebackers",
			groups: ["LB", "EDGE"],
		},
		{
			short: "DB",
			title: "Defensive Backs",
			groups: ["S", "CB"],
		},
	];

	get list() {
		return this._positions;
	}

	@computed get byShortName() {
		return keyBy(this._positions, "short");
	}
}
