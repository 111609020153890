import React, {InputHTMLAttributes} from "react";
import styled from "styled-components";

const CheckboxWrapper = styled.label`
	display: block;
	position: relative;
	margin-bottom: 12px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	text-align: left;
	padding-left: 26px;
`;

const CheckboxCheckmark = styled.span`
	position: absolute;
	top: 2px;
	left: 0;
	height: 16px;
	width: 16px;
	background-color: transparent;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	border: 1px solid #1b48e0;

	&:after {
		content: "";
		position: absolute;
		display: none;

		left: 5px;
		top: 1px;
		width: 3px;
		height: 8px;
		border: solid #1b48e0;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
`;

const CheckboxText = styled.span`
	color: #555555;
	font-size: 12px;
	letter-spacing: 0;
	line-height: 22px;
`;

const CheckboxInput = styled.input`
	opacity: 0;
	height: 0;
	width: 0;
	z-index: -1;
	pointer-events: none;

	&:checked + ${CheckboxCheckmark} {
		&:after {
			display: block;
		}
	}
`;

export const Checkbox: React.FC<InputHTMLAttributes<HTMLInputElement>> = ({
	children,
	...rest
}) => {
	return (
		<CheckboxWrapper>
			<CheckboxText>{children}</CheckboxText>
			<CheckboxInput type="checkbox" {...rest} />
			<CheckboxCheckmark />
		</CheckboxWrapper>
	);
};
