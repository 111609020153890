import React from "react";
import styled from "styled-components";
import infoImage from "assets/img/icons/info.svg";
import ReactTooltip from "react-tooltip";
import {uniqueId} from "lodash";
import {useStore} from "modules/hooks";
const ToolTipIcon = styled.div`
	position: relative;
	width: 22px;
	height: 22px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	.on-hover {
		display: none;
	}
	&:hover {
		.on-idle {
			display: none;
		}
		.on-hover {
			display: block;
		}
	}
`;
const Wrapper = styled.div`
	display: inline;
	.customTheme {
		color: #ffffff !important;
		&.place-bottom,
		&.place-left,
		&.place-right,
		&.place-top {
			background: #fff;
			text-align: left;
			opacity: 1;
			max-width: 360px;
			filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.2));
		}
		&.place-top {
			margin-top: -25px;
			margin-left: -2px;
			&:after {
				display: none;
			}
			&:before {
				bottom: -20px;
				border-top-color: #fff !important;
				border-top-style: solid !important;
				border-top-width: 20px !important;
				border-left-width: 8px;
				border-right-width: 8px;
				margin-left: 0;
				transform: translateX(-50%);
			}
		}
		&.place-bottom {
			margin-top: 25px;
			&:after {
				display: none;
			}
			&:before {
				top: -20px;
				border-bottom-color: #fff !important;
				border-bottom-style: solid !important;
				border-bottom-width: 20px !important;
				border-left-width: 8px;
				border-right-width: 8px;
				margin-left: 0;
				transform: translateX(-50%);
			}
		}
		&.place-left {
			margin-left: -20px;
			&:after {
				display: none;
			}
			&:before {
				right: -20px;
				border-left-color: #fff !important;
				border-left-style: solid !important;
				border-left-width: 20px !important;
				border-top-width: 8px;
				border-bottom-width: 8px;
				margin-top: 0;
				transform: translateY(-50%);
			}
		}
		&.place-right {
			margin-left: 20px;
			&:after {
				left: -20px;
				border-right-color: #fff !important;
				border-right-style: solid !important;
				border-right-width: 20px !important;
				border-top-width: 8px;
				border-bottom-width: 8px;
				margin-top: 0;
				transform: translateY(-50%);
			}
			&:before {
				display: none;
			}
		}
	}
`;
interface ITooltipProps {
	customElement?: React.FC<unknown>;
}
export const Tooltip: React.FC<ITooltipProps> = (props) => {
	const {children, customElement, ...rest} = props;
	const id = uniqueId("tooltip-");
	const Component = customElement;
	const customProps = {
		"data-tip": true,
		"data-for": id,
	};
	return (
		<Wrapper className="tooltip" {...rest}>
			{Component ? (
				<Component {...customProps} />
			) : (
				<ToolTipIcon {...customProps}>
					<img src={infoImage} alt="info" />
				</ToolTipIcon>
			)}
			{/*Typescript check disabled due to false warning*/}
			{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
			{/* @ts-ignore*/}
			<ReactTooltip
				className="customTheme"
				id={id}
				effect="solid"
				aria-haspopup="true"
				place="top"
			>
				{children}
			</ReactTooltip>
		</Wrapper>
	);
};

const TooltipContentWrapper = styled.div`
	background: #2f2ff9;
	box-sizing: border-box;
	font-size: 16px;
	text-align: center;
	position: relative;
	color: #fff;
	padding: 20px;

	h2 {
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 10px;
	}

	p {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 10px;
	}
`;

export const TooltipContent = () => {
	const {I18n} = useStore();
	return (
		<TooltipContentWrapper>
			<h2>{I18n.t("tooltip.player_video.title")}</h2>
			<p>{I18n.t("tooltip.player_video.body")}</p>
		</TooltipContentWrapper>
	);
};
