import React from "react";
import styled from "styled-components";

const Svg = styled.svg``;

export const CircularLoader: React.FC = () => (
	<Svg
		width="24px"
		height="24px"
		viewBox="0 0 100 100"
		preserveAspectRatio="xMidYMid"
	>
		<circle
			cx="50"
			cy="50"
			fill="none"
			stroke="currentColor"
			strokeWidth="13"
			r="30"
			strokeDasharray="141.37166941154067 49.12388980384689"
		>
			<animateTransform
				attributeName="transform"
				type="rotate"
				repeatCount="indefinite"
				dur="2.5"
				values="0 50 50;360 50 50"
				keyTimes="0;1"
			/>
		</circle>
	</Svg>
);

export * from "./PagePreloader";
