import {gte} from "lodash";
import {action, observable, computed, makeAutoObservable} from "mobx";
import {SessionStorage} from "modules/utils";
import {IS_COMING_SOON, SYNDICATE} from "modules/constant";

export class ComingSoon {
	@observable _isComingSoonEnabled = IS_COMING_SOON;

	@computed public get isComingSoonEnabled() {
		return this._isComingSoonEnabled;
	}
	@observable private _isShowSponsor = this.isSyndicate("uk");

	@observable private _isShowDESponsor = this.isSyndicate("de");

	@observable private _actualTries = 0;
	private _MAX_TRIES = 10;

	public isSyndicate(syndicate: string): boolean {
		return SYNDICATE === syndicate;
	}

	constructor() {
		makeAutoObservable(this);
	}

	@computed public get isShowSponsor(): boolean {
		return this._isShowSponsor;
	}

	@computed public get isShowDESponsor(): boolean {
		return this._isShowDESponsor;
	}

	@action private _disableComingSoon() {
		this._isComingSoonEnabled = false;
		SessionStorage.SET(
			"isComingSoonEnabled",
			JSON.stringify(this._isComingSoonEnabled)
		);
	}

	@action public handleSecretState = () => {
		this._actualTries++;

		if (gte(this._actualTries, this._MAX_TRIES)) {
			this._disableComingSoon();
		}
	};
}
