import React from "react";
import styled from "styled-components";
import {useStore} from "modules/hooks";
import {observer} from "mobx-react";

const mobileBreakpoint = "576px";

const EmptyLiveDraftWrapper = styled.div`
	box-sizing: border-box;
	color: #ffffff;
	font-size: 30px;
	font-weight: 900;
	letter-spacing: 0;
	text-align: center;
	border: 1px solid #757575;
	background-color: rgba(0, 0, 0, 0.3);
	text-transform: uppercase;
	width: 300px;
	min-width: 300px;
	height: 115px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;

	@media screen and (max-width: ${mobileBreakpoint}) {
		width: 100%;
		height: 50px;
		font-size: 18px;
	}
`;

const DraftStartsHeader = styled.div`
	color: #ffffff;
	font-size: 20px;
	font-weight: 900;
	letter-spacing: 0;
	padding: 11px 0;
	display: flex;
	align-items: center;
	justify-content: center;

	@media screen and (max-width: ${mobileBreakpoint}) {
		font-size: 12px;
		width: 25%;
		padding: 0 12px;
		box-sizing: border-box;
		text-align: center;
	}
`;

const DraftBody = styled.div`
	display: flex;
	justify-content: space-between;
	padding-bottom: 16px;
	align-items: flex-end;

	@media screen and (max-width: ${mobileBreakpoint}) {
		width: 75%;
		padding: 0;
	}
`;

const DraftItem = styled.div`
	width: 33%;
`;

const DraftItemMainNumber = styled.h4`
	color: #ffffff;
	font-size: 30px;
	font-weight: 900;
	letter-spacing: 0;
	line-height: 36px;
	text-align: center;
	text-transform: uppercase;

	@media screen and (max-width: ${mobileBreakpoint}) {
		font-size: 12px;
		font-weight: bold;
		line-height: 22px;
	}
`;

const DraftItemSub = styled.h5`
	text-transform: uppercase;
	text-align: center;
	color: #ffffff;

	@media screen and (max-width: ${mobileBreakpoint}) {
		font-size: 12px;
		font-weight: bold;
	}
`;

const Divider = styled.div`
	width: 2px;
	max-width: 1px;
	height: 100%;
	min-height: 46px;
	border-left: 1px solid #757575;

	@media screen and (max-width: ${mobileBreakpoint}) {
		min-height: calc(50px - 16px);
	}
`;

const OnlyMobileDivider = styled(Divider)`
	display: none;
	@media screen and (max-width: ${mobileBreakpoint}) {
		display: block;
	}
`;

const FilledLiveDraftWrapper = styled.div`
	width: 300px;
	min-width: 300px;
	height: 115px;
	box-sizing: border-box;
	border: 1px solid #757575;
	background-color: #333333;
	z-index: 1;

	@media screen and (max-width: ${mobileBreakpoint}) {
		width: 100%;
		display: flex;
		flex-wrap: nowrap;
		height: 50px;
		padding: 8px 0;
	}
`;

export const LiveDraft: React.FC = observer(() => {
	const {lockoutController, I18n} = useStore();
	const {isLockout, time} = lockoutController;

	if (lockoutController.isDraftEnd) {
		return (
			<EmptyLiveDraftWrapper>
				<span>{I18n.t("draft_widget.end")}</span>
			</EmptyLiveDraftWrapper>
		);
	}

	if (isLockout) {
		return (
			<EmptyLiveDraftWrapper>
				<span>{I18n.t("draft_widget.started")}</span>
			</EmptyLiveDraftWrapper>
		);
	}

	return (
		<FilledLiveDraftWrapper>
			<DraftStartsHeader>
				{I18n.t("draft_widget.title")}
			</DraftStartsHeader>
			<OnlyMobileDivider />
			<DraftBody>
				<DraftItem>
					<DraftItemMainNumber>{time.days}</DraftItemMainNumber>
					<DraftItemSub>{I18n.t("draft_widget.days")}</DraftItemSub>
				</DraftItem>
				<Divider />
				<DraftItem>
					<DraftItemMainNumber>{time.hours}</DraftItemMainNumber>
					<DraftItemSub>{I18n.t("draft_widget.hours")}</DraftItemSub>
				</DraftItem>
				<Divider />
				<DraftItem>
					<DraftItemMainNumber>{time.minutes}</DraftItemMainNumber>
					<DraftItemSub>{I18n.t("draft_widget.mins")}</DraftItemSub>
				</DraftItem>
			</DraftBody>
		</FilledLiveDraftWrapper>
	);
});
