export class Squad {
	public id: number = 0;
	public name: string = "";
	public shortName: string = "";
	public pickOrder: number[] = [];
	public teamNeeds: string[] = [];

	constructor(args: Squad) {
		Object.assign(this, args);
	}
}
