export class Player {
	public id: number = 0;
	public firstName: string = "";
	public lastName: string = "";
	public college: string = "";
	public position: string = "";
	public link: string = "";
	public pick: number = 0;
	public rank: number = 0;
	public positionMapping: string[] = [];

	constructor(args: Player) {
		Object.assign(this, args);
	}
}
