import React, { createContext, FC } from "react";
import { RootStore } from "./RootStore";

export const StoreContext = createContext<RootStore>({} as RootStore);

export interface IStoreProvider {
  store: RootStore;
}

export const StoreProvider: FC<IStoreProvider> = ({ children, store }) => (
  <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
);

export * from "./RootStore";
