import React from "react";
import {observer} from "mobx-react";
import {Exist} from "views/components/Exist";
import {useStore} from "modules/hooks";
import styled from "styled-components";
import adLogo from "assets/img/Apuestas-Deportivas-Blanco.png";

const mobileBreakpoint = "576px";

const SportLogo = styled.a`
	width: 120px;
	height: 36px;
	display: block;
	background-image: url(${adLogo});
	background-size: contain;
	background-repeat: no-repeat;
	margin-left: 24px;
	margin-right: 12px;

	@media screen and (max-width: ${mobileBreakpoint}) {
	}
`;

export const SponsorMX: React.FC = observer(() => {
	const {SEOManager} = useStore();

	return (
		<Exist when={SEOManager.isShowEightLogo}>
			<SportLogo href={SEOManager.getAdLink} />
		</Exist>
	);
});
