import React, {useCallback} from "react";
import styled from "styled-components";
import {Exist} from "views/components/Exist";
import {GameManager} from "modules/stores/Controllers/GameManager";
import {SquadLogo} from "views/components/Squad";
import {useStore} from "modules/hooks";
import {observer} from "mobx-react";
import playIcon from "assets/img/icons/play.png";
import {SPlayerNameWrapper, SUserPlayIcon} from "views/components/DraftItem";
import {TooltipContent} from "views/components/ToolTip";
import ReactTooltip from "react-tooltip";

const StyledDraftItem = styled.div`
	padding: 18px 16px;
	max-height: 70px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	user-select: none;

	&:nth-child(odd) {
		background: #f8f8f8;
	}
`;

const PlayerNumber = styled.span`
	color: #616161;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0;
	padding-left: 10px;
	min-width: 35px;
`;

const PlayerName = styled.p`
	color: #333333;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 20px;
	text-transform: uppercase;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
`;

const PlayerPosition = styled.span`
	color: #757575;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 14px;
	text-transform: uppercase;
`;

const PlayerCollege = styled(PlayerPosition)`
	padding-left: 10px;
`;

const PlayerLogoWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 18px;
`;

const SPoolUserPlayIcon = styled(SUserPlayIcon)`
	left: -26px;
`;

type IProps = typeof GameManager.prototype.draftOrder[number];

export const SquadPickItem: React.FC<IProps> = observer(
	({orderID, squad, squadPickedPlayer}) => {
		const {overallPositions, playerModalManager} = useStore();
		const pos = squadPickedPlayer?.position;
		const playerPosition = pos
			? overallPositions.byShortName[pos].full
			: "";

		const openModal = useCallback(() => {
			playerModalManager.openModal(squadPickedPlayer?.id);
		}, [playerModalManager, squadPickedPlayer?.id]);

		return (
			<StyledDraftItem>
				<PlayerLogoWrapper>
					<SquadLogo squadID={squad?.id} />
					<PlayerNumber>{orderID}.</PlayerNumber>
				</PlayerLogoWrapper>
				<Exist when={Boolean(squadPickedPlayer)}>
					<SPlayerNameWrapper>
						<Exist when={!!squadPickedPlayer?.link}>
							<SPoolUserPlayIcon
								onClick={openModal}
								src={playIcon}
								alt="play"
								data-tip
							/>
						</Exist>
						<PlayerName onClick={openModal}>
							{squadPickedPlayer?.firstName}{" "}
							{squadPickedPlayer?.lastName}
						</PlayerName>
						<PlayerPosition>{playerPosition}</PlayerPosition>
						<PlayerCollege>
							{squadPickedPlayer?.college}
						</PlayerCollege>
					</SPlayerNameWrapper>
				</Exist>
				{/*Typescript check disabled due to false warning*/}
				{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
				{/* @ts-ignore*/}
				<ReactTooltip
					delayHide={250}
					effect="solid"
					backgroundColor="#2f2ff9"
					place="bottom"
				>
					<TooltipContent />
				</ReactTooltip>
			</StyledDraftItem>
		);
	}
);
