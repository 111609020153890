import {computed, makeAutoObservable, observable} from "mobx";
import {SYNDICATE} from "modules/constant";

export class SEOManager {
	@observable private _isLeaderBoardShowAd = false;
	@observable private _isShowAd = false /*this.isSyndicate(
		"mx"
	) || this.isSyndicate("de")*/;
	@observable private _isShowEightLogo =
		false; /* this.isSyndicate("mx") || this.isSyndicate("de")*/
	@observable private _adUrl =
		"https://record.betcrisaffiliates.com/_bWFFBm_zl3cdimWE6PdS1WNd7ZgqdRLk/1/";
	@observable private _isShowUKSponsor = this.isSyndicate("uk");
	@observable private _isShowDESponsor = this.isSyndicate("de");

	constructor() {
		makeAutoObservable(this);
	}

	public isSyndicate(syndicate: string): boolean {
		return SYNDICATE === syndicate;
	}

	@computed public get isShowAd(): boolean {
		return this._isShowAd;
	}

	@computed get isShowEightLogo(): boolean {
		return this._isShowEightLogo;
	}

	@computed get isShowUKSponsor(): boolean {
		return this._isShowUKSponsor;
	}

	@computed get isShowDESponsor(): boolean {
		return this._isShowDESponsor;
	}

	@computed get isLeaderBoardShowAd(): boolean {
		return this._isLeaderBoardShowAd;
	}

	@computed get getAdLink(): string {
		return this._adUrl;
	}
}
