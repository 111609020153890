import React, {Fragment, useEffect} from "react";
import {PagePreloader} from "views/components/Preloader";
import {useStore} from "modules/hooks";
import {observer} from "mobx-react";

export const GigyaGate: React.FC = observer(({children}) => {
	const {authController} = useStore();

	useEffect(() => {
		authController.initSSO();
	}, [authController]);

	if (!authController.isSessionChecked) {
		return <PagePreloader />;
	}

	return <Fragment>{children}</Fragment>;
});
