import {action, computed, makeAutoObservable, observable} from "mobx";
import {GameManager} from "modules/stores/Controllers/GameManager";
import {JSONCollection, Player} from "modules/stores/Models";

export class PlayerModalManager {
	@observable public isModalOpen: boolean = false;
	@observable private playerId?: number;

	constructor(
		private gameController: GameManager,
		private players: JSONCollection<Player>
	) {
		makeAutoObservable(this);
	}

	@computed
	public get playerData(): Player {
		return this.players.byID[this.playerId || 0];
	}

	@action
	public openModal(playerId?: number): void {
		if (!playerId) {
			return;
		}
		this.playerId = playerId;
		this.isModalOpen = true;
	}

	@action
	public closeModal(): void {
		this.playerId = undefined;
		this.isModalOpen = false;
	}
}
