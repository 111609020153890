import {isEqual, noop} from "lodash";
import {
	action,
	computed,
	makeAutoObservable,
	observable,
	runInAction,
} from "mobx";
import {
	GIGYA_KEY,
	IS_REGISTRATION_DISABLED,
	Language,
	LoadState,
	SYNDICATE,
} from "modules/constant";
import {
	Api,
	ApiError,
	ConnextraType,
	createConnextraScriptTag,
	IAPILogin,
	IAPIUserCreate,
	IGigyaResponse,
	PersistStorage,
} from "modules/utils";
import {ErrorManager} from "modules/stores/Controllers/ErrorManager";
import {IApiResponse} from "modules/types/common";

interface IUser {
	firstName: string;
	lastName: string;
	email: string;
	username: string;
	terms: boolean;
	notificationsEnabled: boolean;
	country: string;
	dob: string;
	language: string;
	id: number;
	recovered: boolean;
}

export class Auth {
	@observable private gigyaResponse?: IGigyaResponse;
	@observable public user?: IUser;
	@observable public isSessionChecked = false;
	@observable public isTermsModalVisible = false;
	@observable public isRecoveredModalVisible = false;
	@observable public isWelcomeModalVisible = false;
	@observable public isTourVisible = false;
	@observable private createAccountApiState = LoadState.IDLE;
	@observable private logoutApiState = LoadState.IDLE;

	@computed public get isCreateAccountProcessing() {
		return isEqual(this.createAccountApiState, LoadState.Requested);
	}

	@computed public get isLogoutProcessing() {
		return isEqual(this.logoutApiState, LoadState.Requested);
	}

	@computed public get isLoggedIn() {
		return Boolean(this.user);
	}
	@computed public get isRecovered() {
		return Boolean(this.user && this.user?.recovered);
	}

	constructor(private errorManager: ErrorManager) {
		makeAutoObservable(this);
	}

	@action private markSessionAsChecked = () => {
		this.isSessionChecked = true;
	};

	@action private clearUser = () => {
		this.user = undefined;
	};

	@action private hideTermsModal = () => {
		this.isTermsModalVisible = false;
		this.createAccountApiState = LoadState.Received;
	};

	@action private hideRecoverModal = () => {
		this.isRecoveredModalVisible = false;
		this.createAccountApiState = LoadState.Received;
	};

	@action public hideWelcomeModal = () => {
		this.isWelcomeModalVisible = false;
		const isShowed = PersistStorage.GET("tourShowed") !== null;

		if (!isShowed) {
			this.isTourVisible = true;
		}

		PersistStorage.SET("tourShowed", true);
	};

	@action public showTour = () => {
		this.isTourVisible = true;
	};

	@action public hideTour = () => {
		this.isTourVisible = false;
	};

	private handleNetworkError = (response: ApiError) => {
		this.errorManager.setError(response.message);
	};

	@action private handleLoginError = (response: ApiError) => {
		if (isEqual(response.code, ApiError.USER_NOT_REGISTERED)) {
			this.isTermsModalVisible = true;
		} else {
			this.errorManager.setError(response.message);
		}
	};

	@action private storeUserFromRequest = (
		response: IApiResponse<{user: IUser}>
	) => {
		this.user = response.result?.user;
		if (!response.result?.user.recovered) {
			this.isRecoveredModalVisible = true;
		}
	};

	@action public APICreateUser = (
		args: Pick<IAPIUserCreate, "notificationsEnabled" | "username">
	) => {
		if (!this.gigyaResponse) {
			throw Error("Gigya data should not be empty!");
		}

		this.createAccountApiState = LoadState.Requested;

		return Api.User.create<{user: IUser}>({
			...this.gigyaResponse,
			// TODO put language depend on build
			language: Language.EN,
			...args,
		})
			.then(this.storeUserFromRequest)
			.catch(this.handleNetworkError)
			.finally(this.hideTermsModal);
	};

	@action public APIRecoverUser = () => {
		if (!this.gigyaResponse) {
			throw Error("Gigya data should not be empty!");
		}

		this.createAccountApiState = LoadState.Requested;

		return Api.User.recover<{user: IUser}>()
			.then(this.storeUserFromRequest)
			.catch(this.handleNetworkError)
			.finally(this.hideRecoverModal);
	};

	@action private APILogin = (params: IAPILogin) => {
		return Api.Auth.sso_login<{user: IUser}>(params)
			.then(this.storeUserFromRequest)
			.catch(this.handleLoginError)
			.finally(() => {
				if (this.user?.id) {
					createConnextraScriptTag(ConnextraType.LOGIN, this.user.id);
				}
			});
	};

	public goToMyAccount = () => {
		window.location.href = `https://id.nfl.com/account?redirectUrl=${window.location.href}`;
	};

	public SSOLogin = () => {
		if (SYNDICATE === "ca") {
			window.gtag?.("event", "conversion", {
				allow_custom_scripts: true,
				send_to: "DC-10179195/nflca0/nflca000+standard",
			});
		}

		window.gigya?.sso.login({
			authFlow: "redirect",
			apiKey: GIGYA_KEY,
		});
	};

	@action public SSOLogout = () => {
		this.logoutApiState = LoadState.Requested;
		window.gigya?.accounts.logout();
	};

	public initSSO = () => {
		if (IS_REGISTRATION_DISABLED) {
			this.hideWelcomeModal();
			return this.markSessionAsChecked();
		}

		this.getSSOUser()
			.then(this.APILogin)
			.catch(noop) // We don't won't to do anything if SSO doesn't return a user.
			.finally(this.markSessionAsChecked);

		window.gigya?.accounts.addEventHandlers({
			onLogout: this.handleSSOLogout,
		});
	};

	@action private handleSSOLogout = () => {
		this.logoutApiState = LoadState.Requested;

		Api.Auth.sso_logout()
			.then(this.clearUser)
			.catch(this.handleNetworkError)
			.finally(() =>
				runInAction(() => {
					this.logoutApiState = LoadState.Received;
				})
			);
	};

	@action private getSSOUser = () => {
		return new Promise<IAPILogin>((resolve, reject) => {
			window.gigya?.accounts.getAccountInfo({
				callback: (resp) => {
					if (resp.errorCode) {
						runInAction(() => {
							this.isWelcomeModalVisible = true;
							reject(resp);
						});

						return;
					}

					runInAction(() => {
						const loginParams = {
							email: resp.profile.email,
							gigyaId: resp.UID,
							gigyaSignature: resp.UIDSignature,
							gigyaSignatureTimestamp: resp.signatureTimestamp,
						};

						this.gigyaResponse = {
							...resp.profile,
							...loginParams,
						};

						resolve(loginParams);
					});
				},
			});
		});
	};
}
