import styled from "styled-components";

export const Container = styled.div`
	margin: 0 auto;
	padding: 0 30px;
	box-sizing: border-box;
	max-width: 1600px;
	width: 100%;

	@media (max-width: 1000px) {
		padding: 0 10px;
	}
`;
