import React, {TextareaHTMLAttributes} from "react";
import {inputStyle} from "views/components/Inputs/Input";
import styled from "styled-components";

const TextAreaWrapper = styled.div`
	position: relative;
	box-sizing: border-box;
`;

const STextArea = styled.textarea`
	${inputStyle};
	resize: none;
	min-height: 150px;
`;

export const TextArea: React.FC<TextareaHTMLAttributes<HTMLTextAreaElement>> = (
	props
) => {
	return (
		<TextAreaWrapper>
			<STextArea {...props}>{props.children}</STextArea>
		</TextAreaWrapper>
	);
};
