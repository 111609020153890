import {Language} from "./enum";
import {SessionStorage} from "modules/utils/Storage";
import {isEqual} from "lodash";

export const API_URL = process.env.REACT_APP_API_URL || "";
export const SHARE_URI = process.env.REACT_APP_PURE_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";
export const APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || "";
export const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL || "";
export const GIGYA_KEY = process.env.REACT_APP_GIGYA_KEY || "";
export const SYNDICATE = process.env.REACT_APP_SYNDICATE || "ca";

export const IS_REGISTRATION_DISABLED = JSON.parse(
	process.env.REACT_APP_IS_REGISTRATION_DISABLED || "false"
) as boolean;
export const IS_WITH_PRIZES = JSON.parse(
	process.env.REACT_APP_IS_WITH_PRIZES || "true"
) as boolean;

export const MAX_LINEUP_SIZE = 15;
export const MAX_SQUADS_OUTPUT = 31;

export const ANSWER_SCORES = {
	ACCURATE: 100,
	ONE_PICK_AWAY: 50,
	TWO_PICKS_AWAY: 20,
	INCORRECT: 0,
};

export const SQUAD_IMAGE = {
	get: (id?: number) => (id ? `${IMAGES_URL}squads/${id}.png` : ""),
};

export const BROADCASTER_IMAGE = {
	get: (id?: string) =>
		id ? `${IMAGES_URL}image/broadcasters/${id}.png` : "",
};

export const IS_COMING_SOON = isEqual(
	true,
	JSON.parse(
		SessionStorage.GET("isComingSoonEnabled") ??
			(process.env.REACT_APP_IS_COMING_SOON || "false")
	)
);

const locales = {
	mx: Language.ES,
	de: Language.DE,
	en: Language.EN,
	ca: Language.EN,
	uk: Language.EN,
};

export const LOCALE = locales[SYNDICATE as "de"];

export * from "./enum";
