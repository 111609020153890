import React, {Fragment} from "react";
import {
	ModalBody,
	ModalBodyHeading,
	ModalContainer,
	ModalHeader,
	ModalOverlay,
} from "views/components/Modals/ModalBase";
import styled from "styled-components";
import {observer} from "mobx-react";
import facebook from "assets/img/icons/facebook.svg";
import twitter from "assets/img/icons/twitter.svg";
import download from "assets/img/icons/upload-w.svg";
import {useMediaQuery, useStore} from "modules/hooks";
import {PureButton} from "views/components/Button";
import {BroadCastInfo, Exist} from "views/components";
import useCollapse from "react-collapsed";
import {IS_REGISTRATION_DISABLED} from "modules/constant";

const TermsModalBody = styled(ModalBody)`
	padding: 40px 110px;
`;

const SocialWrapper = styled.div`
	display: flex;
	justify-content: center;
	gap: 20px;
	margin-bottom: 20px;
`;

const DownloadButton = styled(PureButton)`
	width: 50px;
	height: 50px;
	border-radius: 50px;
	border: 1px solid #000;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 20px;
	}
`;

const ShareImage = styled.a`
	img {
		max-width: 100%;
		margin-bottom: 10px;
	}
`;

export const ShareModal: React.FC = observer(() => {
	const {gameManageController, I18n, shareManager} = useStore();
	const isDesktop = useMediaQuery("(min-width: 768px)");
	const {getCollapseProps, getToggleProps} = useCollapse();

	if (!gameManageController.isVisibleShareModal) {
		return null;
	}
	// TODO: uncomment when we need to display Desktop image
	// const imageUrl = isDesktop
	// 	? gameManageController.picksImageUrl
	// 	: gameManageController.picksMobileImageUrl;

	const imageUrl = gameManageController.picksMobileImageUrl;

	return (
		<Fragment>
			<ModalOverlay onClick={gameManageController.hideShareModal} />
			<ModalContainer
				onCloseClick={gameManageController.hideShareModal}
				showClose={true}
			>
				<ModalHeader title={I18n.t("modals.share.title")} />
				<TermsModalBody>
					<ModalBodyHeading>
						{I18n.t("modals.share.share_text")}
					</ModalBodyHeading>
					<SocialWrapper>
						<Exist when={!IS_REGISTRATION_DISABLED}>
							<PureButton
								onClick={() => shareManager.shareViaFacebook()}
							>
								<img src={facebook} alt="FB" />
							</PureButton>
							<PureButton
								onClick={() => shareManager.shareViaTwitter()}
							>
								<img src={twitter} alt="Twitter" />
							</PureButton>
						</Exist>

						<Exist when={!isDesktop}>
							<DownloadButton {...getToggleProps()}>
								<img src={download} alt="Download" />
							</DownloadButton>
						</Exist>
						<Exist when={isDesktop}>
							<DownloadButton
								as={"a"}
								target="_blank"
								download="image"
								href={imageUrl}
							>
								<img src={download} alt="Download" />
							</DownloadButton>
						</Exist>
					</SocialWrapper>
					<Exist when={!isDesktop}>
						<div {...getCollapseProps()}>
							<ModalBodyHeading>
								{I18n.t("picks_image.modal.instructions")}
							</ModalBodyHeading>
							<ShareImage
								target="_blank"
								download="image"
								rel="noreferrer"
								href={imageUrl}
							>
								<img src={imageUrl} alt="Share" />
							</ShareImage>
						</div>
					</Exist>
					<BroadCastInfo />
				</TermsModalBody>
			</ModalContainer>
		</Fragment>
	);
});
