export enum LoadState {
	IDLE,
	Requested,
	Received,
}

export enum Language {
	EN = "en",
	ES = "es",
	DE = "de",
}

export enum Order {
	ASC = "ASC",
	DESC = "DESC",
}
