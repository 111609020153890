import styled from "styled-components";
import React, {Fragment, useCallback, useMemo, useState} from "react";
import {useMediaQuery, useStore} from "modules/hooks";
import {observer} from "mobx-react";
import {PlayerItem} from "views/components/PlayerItem";
import {SquadPickItem} from "views/components/SquadPickItem";
import {ADItem} from "views/components/AdItem";
import {Exist} from "views/components/Exist";
import {Input, TooltipWrapper} from "views/components/Inputs";
import {CustomSelect, IDropdownOptions} from "views/components/CustomSelect";
import search from "assets/img/icons/search.svg";
import backIcon from "assets/img/icons/back.svg";
import {ValueType} from "react-select/src/types";
import {find} from "lodash";
import blackDecorate from "assets/img/blackDecorate.jpg";

const mobileBreakpoint = "860px";

const PoolWrapper = styled.div`
	width: 100%;
	max-height: 1250px;
	overflow: hidden;

	@media screen and (max-width: ${mobileBreakpoint}) {
		max-height: unset;
	}
`;

const PlayerPoolHeading = styled.div`
	padding: 20px 0 20px 30px;
	color: #ffffff;
	font-size: 16px;
	letter-spacing: 0;
	text-transform: uppercase;
	background: url(${blackDecorate}) center;
	max-height: 60px;
	box-sizing: border-box;

	@media screen and (max-width: ${mobileBreakpoint}) {
		width: 100%;
		padding: 8px 17px;
		display: flex;
		align-items: center;
	}
`;

const PoolHeading = styled.div`
	display: flex;
	justify-content: stretch;
	min-height: 60px;

	> div {
		width: 100%;
	}

	@media screen and (max-width: ${mobileBreakpoint}) {
		flex-wrap: wrap;
	}
`;

const PlayersWrapper = styled.div<{isMx: boolean}>`
	padding: 0 14px 0 35px;
	margin-right: 20px;
	max-height: ${(p) => (p.isMx ? "728px" : "910px")};
	overflow: auto;
	border-bottom: 1px solid #d8d8d8;

	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		background: #f8f8f8;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #1b48e0;
	}

	@media screen and (max-width: ${mobileBreakpoint}) {
		padding: 0;
		margin: 0;

		&::-webkit-scrollbar {
			width: 0;
		}
	}
`;

const SHeadingSearch = styled.div`
	background: url(${blackDecorate});
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 18px 24px;
	box-sizing: border-box;
	max-height: 60px;

	input {
		background: transparent;
		border: none;
		margin-bottom: 0;
		font-size: 16px;
		color: #ffffff;
		font-family: "AllProSans", sans-serif;
		letter-spacing: 0;

		&::placeholder {
			color: #fafafa;
			opacity: 0.6;
		}
	}

	${TooltipWrapper} {
		display: none;
		pointer-events: none;
	}

	@media screen and (max-width: ${mobileBreakpoint}) {
		width: 50% !important;
		padding: 8px 12px;
		max-height: 40px;
	}
`;

const SSearchIcon = styled.img`
	display: block !important;
`;

const SCustomSelect = styled.div`
	min-width: 240px;
	max-width: 240px;

	@media screen and (max-width: ${mobileBreakpoint}) {
		max-width: 50%;
		min-width: 50%;
		height: 40px;
	}
`;

const BackIcon = styled.img`
	margin-right: 12px;
`;

const SBottomAD = styled.div`
	@media screen and (max-width: ${mobileBreakpoint}) {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		background: #ffffff;
	}
`;

const SPoolHeading = styled.h2`
	color: #0b1c17;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 20px;
	margin-bottom: 30px;
	padding: 0 35px;
	text-transform: uppercase;

	@media screen and (max-width: ${mobileBreakpoint}) {
		padding: 0 20px;
	}
`;

const AvailablePlayersList: React.FC = observer(() => {
	const {gameManageController} = useStore();

	return (
		<Fragment>
			{gameManageController.players.map((player, index) => {
				const className = index === 0 ? "step-2" : "";

				return (
					<PlayerItem
						className={className}
						player={player}
						index={index}
						key={player.id}
					/>
				);
			})}
		</Fragment>
	);
});

const PickedPlayersList: React.FC = observer(() => {
	const {gameManageController} = useStore();

	return (
		<Fragment>
			{gameManageController.draftOrder.map((draftItem) => (
				<SquadPickItem {...draftItem} key={draftItem.orderID} />
			))}
		</Fragment>
	);
});

export const PlayerPool: React.FC = observer(() => {
	const {
		gameManageController,
		poolPositions,
		lockoutController,
		I18n,
		SEOManager,
	} = useStore();
	const {isLockout} = lockoutController;
	const isDesktop = useMediaQuery(`(min-width: ${mobileBreakpoint})`);
	const [filterListTitle, setFilterListTitle] = useState("");

	const handleSearchByName = useCallback(
		(event: React.SyntheticEvent<HTMLInputElement>) => {
			gameManageController.setFilterByNameValue(
				event.currentTarget.value
			);
		},
		[gameManageController]
	);

	const handleSearchByPosition = useCallback(
		(selection: ValueType<IDropdownOptions, false>) => {
			const value = selection!.value;
			gameManageController.setFilterByPositionValue(value);
			setFilterListTitle(value ? selection!.label : "");
		},
		[gameManageController]
	);

	const options = useMemo(() => {
		const opt = [
			{value: "", label: I18n.t("draft_page.filter.all_players")},
		];

		poolPositions.list.forEach(({short, title}) => {
			opt.push({
				value: short,
				label: title,
			});
		});

		return opt;
	}, [poolPositions, I18n]);
	const selectedOption = gameManageController.filterByPositionValue
		? find(
				options,
				(option) =>
					option.value === gameManageController.filterByPositionValue
		  )
		: options[0];

	const goBack = useCallback(() => {
		gameManageController.clearSelectPositionId();
	}, [gameManageController]);

	const poolTitle = isLockout
		? I18n.t("draft_page.draft_order")
		: I18n.t("draft_page.player_pool");

	const selectedFilterListTitle =
		filterListTitle || I18n.t("draft_page.title");

	return (
		<PoolWrapper>
			<PoolHeading>
				<Exist when={isDesktop}>
					<PlayerPoolHeading>{poolTitle}</PlayerPoolHeading>
				</Exist>
				<Exist when={!isDesktop}>
					<PlayerPoolHeading onClick={goBack}>
						<BackIcon src={backIcon} alt="<" />
						{poolTitle}
					</PlayerPoolHeading>
				</Exist>
				<Exist when={!isLockout}>
					<SHeadingSearch>
						<SSearchIcon src={search} alt="search" />
						<Input
							type="text"
							onChange={handleSearchByName}
							defaultValue={gameManageController.filterByName}
							placeholder={
								isDesktop
									? I18n.t("draft_page.search_desktop")
									: I18n.t("draft_page.search")
							}
						/>
					</SHeadingSearch>
					<SCustomSelect className="step-4">
						<CustomSelect
							options={options}
							defaultValue={selectedOption}
							onChange={handleSearchByPosition}
						/>
					</SCustomSelect>
				</Exist>
			</PoolHeading>
			<ADItem />
			<SPoolHeading>
				{isLockout
					? I18n.t("draft_page.picks")
					: selectedFilterListTitle}
			</SPoolHeading>
			<PlayersWrapper isMx={SEOManager.isSyndicate("mx")}>
				<Exist when={!isLockout}>
					<AvailablePlayersList />
				</Exist>
				<Exist when={isLockout}>
					<PickedPlayersList />
				</Exist>
			</PlayersWrapper>
			<SBottomAD>
				<ADItem />
			</SBottomAD>
		</PoolWrapper>
	);
});
