import React, {useCallback} from "react";
import styled, {css} from "styled-components";
import {CTAButtonUppercase} from "views/components/Button";
import {DraftsCounterSlider} from "views/components/DraftsCounterSlider";
import {CircularLoader} from "views/components/Preloader";
import {
	IS_REGISTRATION_DISABLED,
	MAX_LINEUP_SIZE,
	SYNDICATE,
} from "modules/constant";
import {observer} from "mobx-react";
import {useStore} from "modules/hooks";
import {Exist} from "views/components/Exist";

const StyledDraftsCounter = styled.div`
	padding: 10px 20px;
	background: #ffffff;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media screen and (max-width: 860px) {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		border-top: 2px solid #151515;
	}
`;

const StyledPickedDrafts = styled.div`
	color: #1b48e0;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0;
	margin-bottom: 6px;
`;

const SubmitButton = styled(CTAButtonUppercase)`
	width: 105px;
	text-align: center;
	padding: 7px 8px;
	transition: all 0.5s;
	height: auto;
	min-height: 44px;
`;

interface ISliderWrapperProps {
	isFullWidth: boolean;
}

const SliderWrapper = styled.div<ISliderWrapperProps>`
	${(props) =>
		props.isFullWidth
			? css`
					width: 100%;
			  `
			: css`
					width: calc(100% - 110px);
					padding-right: 24px;
			  `}
`;

const ButtonWrapper = styled.div`
	width: 110px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
export const DraftsCounter: React.FC = observer(() => {
	const {
		gameManageController: {pickedSize, savePicks, isSavingPicks},
		authController: {isLoggedIn, SSOLogin, isLogoutProcessing},
		lockoutController: {isLockout},
		I18n,
	} = useStore();

	const handleSave = useCallback(() => {
		if (isLoggedIn) {
			if (SYNDICATE === "ca") {
				window.gtag?.("event", "conversion", {
					allow_custom_scripts: true,
					send_to: "DC-10179195/nflca0/nflca00+standard",
				});
			}

			savePicks();
		} else {
			IS_REGISTRATION_DISABLED ? savePicks('share') : SSOLogin();
		}
	}, [isLoggedIn, savePicks, SSOLogin]);
	const buttonKey = IS_REGISTRATION_DISABLED ? 'picks.button.share' : 'draft_page.submit_no_logged';

	return (
		<StyledDraftsCounter>
			<SliderWrapper isFullWidth={isLockout}>
				<StyledPickedDrafts>
					{pickedSize} / {MAX_LINEUP_SIZE}{" "}
					{I18n.t("draft_page.picks_drafted")}
				</StyledPickedDrafts>
				<DraftsCounterSlider picked={pickedSize} />
			</SliderWrapper>
			<Exist when={!isLockout}>
				<ButtonWrapper>
					<Exist when={isSavingPicks}>
						<CircularLoader />
					</Exist>
					<Exist when={!isSavingPicks}>
						<SubmitButton
							disabled={
								isLogoutProcessing ||
								pickedSize < MAX_LINEUP_SIZE
							}
							onClick={handleSave}
						>
							{isLoggedIn
								? I18n.t("draft_page.submit")
								: I18n.t(buttonKey)}
						</SubmitButton>
					</Exist>
				</ButtonWrapper>
			</Exist>
		</StyledDraftsCounter>
	);
});
