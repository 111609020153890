import {action, makeAutoObservable, observable} from "mobx";
import {Api} from "modules/utils";

type IContentData = {[key: string]: string};

interface IPagesMap {
	[key: string]: IContentData;
}

export class HelpManager {
	@observable loadedPagesMap: IPagesMap = {};

	constructor() {
		makeAutoObservable(this);
	}

	@action
	public loadPage(page: string): void {
		if (!this.loadedPagesMap[page]) {
			void Api.JSON.loadHelpTranslationForPage<IContentData>(page).then(
				(data) => (this.loadedPagesMap[page] = data)
			);
		}
	}
}
