import {Auth} from "modules/stores/Controllers/Auth";
import {shareFB, shareTwitter} from "modules/utils";
import {SYNDICATE} from "modules/constant";
import {makeAutoObservable} from "mobx";

interface ICopy {
	[key: string]: string;
}

export class ShareManager {
	private copy: ICopy = {
		ca: "I’ve just made my top NFL Draft predictions in the Predict the Pick Challenge! Check it out to make your own and compete for the free prizes!",
		uk: "I’ve just made my top NFL Draft predictions in the Predict the Pick Challenge! Check it out to make your own and compete for the free prizes!",
		mx: "¡Acabo de hacer mis mejores predicciones del Draft de la NFL en el desafío Predict the Pick Challenge! ¡Compruébalo para hacer el tuyo y competir por los premios gratuitos!",
		de: "Ich habe gerade meine Top NFL Draft Vorhersagen in der Predict the Pick Challenge abgegeben! Schau’ es dir mal an und gib deine eigenen Tipps ab um kostenlose Preise zu gewinnen!",
	};

	constructor(private auth: Auth) {
		makeAutoObservable(this);
	}

	public shareViaFacebook(): void {
		shareFB({userId: Number(this.auth.user?.id)});
	}

	public shareViaTwitter(): void {
		shareTwitter(
			{userId: Number(this.auth.user?.id)},
			this.copy[SYNDICATE]
		);
	}
}
