import React from "react";
import styled from "styled-components";
import {NavLink} from "react-router-dom";

const Wrapper = styled.div`
	padding: 38px 0 24px 0;
`;

const SHeading = styled.h3`
	color: #333333;
	font-size: 28px;
	font-weight: bold;
	letter-spacing: 0;
	margin-bottom: 24px;
`;

const SBlock = styled.div`
	padding: 28px 20px 26px 20px;
	border-radius: 5px;
	background: #ffffff;
	border-bottom: 5px solid #1b48e0;
`;

const SBlockText = styled.p`
	color: #555555;
	font-size: 14px;
	letter-spacing: 0;
	line-height: 22px;
	margin-bottom: 18px;
`;

const SLink = styled(NavLink)`
	color: #757575;
	font-size: 14px;
	letter-spacing: 0;
	line-height: 22px;
	text-decoration: underline;
`;

export const HowToPlayWidget: React.FC = () => {
	return (
		<Wrapper>
			<SHeading>How to play</SHeading>
			<SBlock>
				<SBlockText>
					Each week the Margin difference score for a player will be
					added to all of their past Margin Difference scores to
					provide their ‘Cumulative Margin’.
				</SBlockText>

				<SLink to="/">Start Drafting</SLink>
			</SBlock>
		</Wrapper>
	);
};
