import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import pageBg from "assets/img/bg.jpg";

const Wrapper = styled.div`
	background: url(${pageBg}) #1a3c58 no-repeat center center;
	background-size: cover;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
`;

const Container = styled.div`
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.5);
	text-align: center;
`;

const Blur = styled.div`
	-webkit-filter: blur(7em);
	filter: blur(7em);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.5);
`;

const Loader = styled.div`
  && {
	position: relative;
	margin: 0 auto;
	top: 50%;
	width: 92px;
	height: 92px;
	display: block;
	overflow: hidden;
  }

  &, div {
	position: relative;
	height: 100%;
	box-sizing: border-box;
	border-radius: 50%;
	padding: 4px;
	border: 2px solid transparent;
	-webkit-animation: rotate linear 3.5s infinite;
	animation: rotate linear 3.5s infinite;
	border-top-color: #007dc3;
	border-left-color: #9ba1a9;
	-webkit-animation-timing-function: cubic-bezier(0.55, 0.38, 0.21, 0.88);
	animation-timing-function: cubic-bezier(0.55, 0.38, 0.21, 0.88);
	-webkit-animation-duration: 3s;
	animation-duration: 3s;
  }

  @keyframes rotate {
	0% {
	  transform: rotate(0deg);
	}
	50% {
	  transform: rotate(180deg);
	}
	100% {
	  transform: rotate(360deg);
	}
`;

export const PagePreloader: React.FC = observer(() => {
	return (
		<Wrapper>
			<Container>
				<Blur />
				<Loader>
					<div>
						<div>
							<div>
								<div>
									<div>
										<div />
									</div>
								</div>
							</div>
						</div>
					</div>
				</Loader>
			</Container>
		</Wrapper>
	);
});
