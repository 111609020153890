import {Api} from "modules/utils/Api";
import {
	Squad,
	I18n,
	JSONCollection,
	Player,
	Lockout,
	PoolPositions,
	OverallPositions,
	TradesAlert,
	Checksums,
	Broadcasters,
} from "modules/stores/Models";
import {Auth} from "modules/stores/Controllers/Auth";
import {ComingSoon} from "modules/stores/Controllers/ComingSoon";
import {ErrorManager} from "modules/stores/Controllers/ErrorManager";
import {GameManager} from "modules/stores/Controllers/GameManager";
import {HelpManager} from "modules/stores/Controllers/HelpManager";
import {PlayerModalManager} from "modules/stores/Controllers/PlayerModalManager";
import {LiveScoringService} from "modules/stores/Controllers/LiveScoring";
import {LeaderBoardManager} from "modules/stores/Controllers/LeaderBoardManager";
import {SEOManager} from "modules/stores/Controllers/SEOManager";
import {ShareManager} from "modules/stores/Controllers/ShareManager";
import {ConfirmationManager} from "modules/stores/Controllers/ConfirmationManager";
import {Contact} from "modules/stores/Controllers/Contact";

export class RootStore {
	authController: Auth;
	squads: JSONCollection<Squad>;
	players: JSONCollection<Player>;
	I18n: I18n;
	comingSoonController: ComingSoon;
	errorManagerController: ErrorManager;
	lockoutController: Lockout;
	gameManageController: GameManager;
	helpManager: HelpManager;
	leaderBoardManager: LeaderBoardManager;
	playerModalManager: PlayerModalManager;
	confirmationManager: ConfirmationManager;
	shareManager: ShareManager;
	poolPositions: PoolPositions;
	overallPositions: OverallPositions;
	liveScoringService: LiveScoringService;
	tradeAlerts: TradesAlert;
	checksums: Checksums;
	SEOManager: SEOManager;
	broadcasters: Broadcasters;
	contactController: Contact;

	constructor() {
		this.squads = new JSONCollection(Squad, Api.JSON.squads);
		this.players = new JSONCollection(Player, Api.JSON.players);
		this.tradeAlerts = new TradesAlert(Api.JSON.trade_alerts);
		this.checksums = new Checksums(Api.JSON.checksums);
		this.I18n = new I18n(Api.JSON.lang);
		this.lockoutController = new Lockout(Api.Lockout.lockout, this.players);
		this.broadcasters = new Broadcasters(Api.JSON.broadcasters);

		this.SEOManager = new SEOManager();
		this.comingSoonController = new ComingSoon();
		this.errorManagerController = new ErrorManager();
		this.poolPositions = new PoolPositions();
		this.overallPositions = new OverallPositions();
		this.authController = new Auth(this.errorManagerController);
		this.helpManager = new HelpManager();
		this.leaderBoardManager = new LeaderBoardManager(
			this.errorManagerController
		);

		this.liveScoringService = new LiveScoringService(
			this.lockoutController,
			this.players,
			this.squads,
			this.tradeAlerts,
			this.checksums
		);

		this.gameManageController = new GameManager(
			this.players,
			this.squads,
			this.poolPositions,
			this.authController,
			this.errorManagerController,
			this.lockoutController,
			this.broadcasters
		);

		this.shareManager = new ShareManager(this.authController);

		this.playerModalManager = new PlayerModalManager(
			this.gameManageController,
			this.players
		);
		this.confirmationManager = new ConfirmationManager();

		this.contactController = new Contact(this.errorManagerController);
	}
}
