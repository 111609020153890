import React, {useCallback} from "react";
import styled from "styled-components";
import {PureButton} from "views/components/Button";
import closeIcon from "assets/img/icons/close.svg";
import {useStore} from "modules/hooks";
import {observer} from "mobx-react";
import {ReactourStepContentArgs} from "reactour";

const Step = styled.div`
	background: #2f2ff9;
	box-sizing: border-box;
	font-size: 16px;
	text-align: center;
	position: relative;
	color: #fff;
	padding: 25px 25px 20px;
	width: 330px;

	h4 {
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 10px;
	}

	p {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 10px;
	}

	${PureButton} {
		text-decoration: underline;
		font-size: 16px;
		color: #fff;

		&:hover {
			text-decoration: none;
		}
	}

	&.dialog-box-step-1 {
		&:before {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 10px 25px 10px;
			border-color: transparent transparent #2f2ff9 transparent;
			position: absolute;
			left: 50%;
			bottom: 100%;
			transform: translateX(-50%);
		}
	}

	&:before {
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		position: absolute;
		transform: translateX(-50%);
	}

	&.dialog-box-step-2 {
		&:before {
			border-width: 25px 10px 0 10px;
			border-color: #2f2ff9 transparent transparent transparent;
			left: 75%;
			top: 100%;
		}
	}

	&.dialog-box-step-3 {
		&:before {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 10px 25px 10px;
			border-color: transparent transparent #2f2ff9 transparent;
			position: absolute;
			left: 3%;
			bottom: 100%;
			transform: translateX(-50%);
		}
	}

	&.dialog-box-step-4 {
		&:before {
			border-width: 0 10px 25px 10px;
			border-color: transparent transparent #2f2ff9 transparent;
			left: 75%;
			bottom: 100%;
		}
	}

	&.dialog-box-step-5 {
		&:before {
			border-width: 0 10px 25px 10px;
			border-color: transparent transparent #2f2ff9 transparent;
			left: 4%;
			bottom: 100%;
		}
	}

	&.dialog-box-step-6 {
		&:before {
			border-width: 25px 10px 0 10px;
			border-color: #2f2ff9 transparent transparent transparent;
			left: 50%;
			top: 100%;
		}
	}
`;

const CloseButton = styled(PureButton)`
	position: absolute;
	right: 5px;
	top: 5px;
`;

const steps = [
	{
		title: "tooltip.first.title",
		content: "tooltip.first.body",
		buttonText: "tooltip.first.button",
	},
	{
		title: "tooltip.second.title",
		content: "tooltip.second.body",
		buttonText: "tooltip.second.button",
	},
	{
		title: "tooltip.third.title",
		content: "tooltip.third.body",
		buttonText: "tooltip.third.button",
	},
	{
		title: "tooltip.fourth.title",
		content: "tooltip.fourth.body",
		buttonText: "tooltip.fourth.button",
	},
	{
		title: "tooltip.fifth.title",
		content: "tooltip.fifth.body",
		buttonText: "tooltip.fifth.button",
	},
	{
		title: "tooltip.sixth.title",
		content: "tooltip.sixth.body",
		buttonText: "tooltip.sixth.button",
	},
];

export const TourSteps: React.FC<ReactourStepContentArgs> = observer(
	({step, goTo, close}) => {
		const {I18n, gameManageController, players} = useStore();
		const isLast = step === steps.length;
		const {title, content, buttonText} = steps[step - 1];

		const handleNext = useCallback(() => {
			if (step === 1) {
				gameManageController.selectPositionID(1);
			}

			if (step === 3) {
				gameManageController.addPlayer(players.entities[0].id);
			}

			isLast ? close() : goTo(step);
		}, [step, goTo, close, isLast, gameManageController, players]);

		return (
			<Step className={`dialog-box-step-${step}`}>
				<CloseButton onClick={close}>
					<img src={closeIcon} alt="close" />
				</CloseButton>
				<h4>{I18n.t(title)}</h4>
				<p>{I18n.t(content)}</p>
				<PureButton onClick={handleNext}>
					{I18n.t(buttonText)}
				</PureButton>
			</Step>
		);
	}
);
