import {action, makeObservable, observable} from "mobx";
import {ApiModel} from "./ApiModel";
import {SYNDICATE} from "modules/constant";
import {IDictionary} from "modules/types/common";
import {get} from "lodash";

type TResponse = IDictionary<IBroadcast[]>;
interface IBroadcast {
	broadcaster: string;
	video: string;
}

export class Broadcasters extends ApiModel<TResponse> {
	@observable public data: IBroadcast[] = [];

	constructor(protected readonly api: () => Promise<TResponse>) {
		super(api);
		makeObservable(this);
	}

	@action protected performResponse(response: TResponse) {
		this.data = get(response, SYNDICATE, []) as IBroadcast[];
	}

	protected onApiError(err: Error): void {
		console.log(err);
	}
}
