import {keyBy} from "lodash";
import {computed} from "mobx";

export class OverallPositions {
	private _positions = [
		{
			short: "S",
			full: "Safety",
			plural: "Safeties",
		},
		{
			short: "EDGE",
			full: "Defensive End",
			plural: "Defensive Ends",
		},
		{
			short: "RB",
			full: "Running back",
			plural: "Running backs",
		},
		{
			short: "WR",
			full: "Wide receiver",
			plural: "Wide receivers",
		},
		{
			short: "CB",
			full: "Cornerback",
			plural: "Cornerbacks",
		},
		{
			short: "DL",
			full: "Defensive Lineman",
			plural: "Defensive Linemans",
		},
		{
			short: "OL",
			full: "Offensive Lineman",
			plural: "Offensive Linemans",
		},
		{
			short: "IOL",
			full: "Interior Offensive Lineman",
			plural: "Interior Offensive Linemans",
		},
		{
			short: "IDL",
			full: "Interior Defensive Lineman",
			plural: "Interior Defensive Linemans",
		},
		{
			short: "LB",
			full: "Linebacker",
			plural: "Linebackers",
		},
		{
			short: "OT",
			full: "Offensive tackle",
			plural: "Offensive tackles",
		},
		{
			short: "TE",
			full: "Tight end",
			plural: "Tight ends",
		},
		{
			short: "QB",
			full: "Quarterback",
			plural: "Quarterbacks",
		},
		{
			short: "DE",
			full: "Defensive end",
			plural: "Defensive ends",
		},
		{
			short: "DT",
			full: "Defensive tackle",
			plural: "Defensive tackles",
		},
	];

	get list() {
		return this._positions;
	}

	@computed get byShortName() {
		return keyBy(this._positions, "short");
	}
}
