import {action, makeAutoObservable, observable} from "mobx";

export class ErrorManager {
	@observable error = "";

	constructor() {
		makeAutoObservable(this);
	}

	@action clearError = () => {
		this.error = "";
	};

	@action setError(msg: string) {
		this.error = msg;
	}
}
