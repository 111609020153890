import React, {useCallback, useState} from "react";
import styled, {css} from "styled-components";
import primaryPlus from "assets/img/icons/plus_primary.svg";
import closeActionImage from "assets/img/icons/close_dark.svg";
import {PureButton} from "views/components/Button";
import {Exist} from "views/components/Exist";
import {GameManager} from "modules/stores/Controllers/GameManager";
import {SquadLogo} from "views/components/Squad";
import {useStore} from "modules/hooks";
import {isEqual} from "lodash";
import {observer} from "mobx-react";
import {DragItem} from "views/components/DragItem";
import {Points} from "views/components/Points";
import warningIcon from "assets/img/icons/warning.svg";
import playIcon from "assets/img/icons/play.png";

interface IStyledDraftItemProps {
	isSelected: boolean;
	isDisabled: boolean;
	isTradeAlert?: boolean;
}

const StyledDraftItem = styled.div<IStyledDraftItemProps>`
	background: #fff;
	padding: 18px 16px 18px 0;
	max-height: 70px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	user-select: none;
	cursor: ${({isDisabled}: IStyledDraftItemProps) =>
		isDisabled ? "default" : "pointer"};

	&:nth-child(odd) {
		background: #f8f8f8;
	}

	${({isTradeAlert}: IStyledDraftItemProps) =>
		isTradeAlert &&
		css`
			-webkit-box-shadow: inset 0 0 0 3px #d42027;
			box-shadow: inset 0 0 0 3px #d42027;
		`}

	${({isSelected}: IStyledDraftItemProps) =>
		isSelected
			? css`
					box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
					border-color: #1b48e0;
					position: relative;
			  `
			: ""};
`;

const PlayerNumber = styled.span`
	color: #616161;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0;
	padding-left: 10px;
	min-width: 35px;
`;

const PlayerName = styled.p`
	color: #333333;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 20px;
	text-transform: uppercase;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
`;

const PlayerPosition = styled.span`
	color: #757575;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 14px;
	text-transform: uppercase;
`;

const PlayerCollege = styled(PlayerPosition)`
	padding-left: 10px;
`;

const ActionImageButton = styled(PureButton)`
	margin-left: auto;
	margin-top: 4px;
	cursor: pointer;
`;

const PlayerLogoWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 18px;
`;

const TeamNeeds = styled(PlayerPosition)`
	position: relative;
	top: -2px;
`;

const SAlertMessageWrapper = styled.div`
	background: #d42027;
	padding: 5px 8px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

const SAlertMessage = styled.span`
	color: #ffffff;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0;
`;

const SAlertIcon = styled.img`
	margin-right: 8px;
	width: 14px;
	height: 14px;
`;

export const SUserPlayIcon = styled.img`
	width: 20px;
	height: 20px;
	position: absolute;
	left: -24px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
`;

export const SPlayerNameWrapper = styled.div`
	position: relative;
`;

type IPropsBasic = typeof GameManager.prototype.lineup[number];

interface IProps {
	isTradeAlert: boolean;
	tradeAlertMessage: string;
}

export const DraftItem: React.FC<Partial<IProps> & IPropsBasic> = observer(
	({
		orderID,
		squad,
		userPickedPlayer,
		score,
		isTradeAlert,
		tradeAlertMessage,
	}) => {
		const [isClicked, setIsClicked] = useState(false);
		const {
			gameManageController,
			lockoutController,
			playerModalManager,
			I18n,
		} = useStore();
		const {isLockout} = lockoutController;

		const hasPlayer = Boolean(userPickedPlayer);
		const isSelected = isEqual(
			orderID,
			gameManageController.selectedPositionID
		);

		const removePlayer = useCallback(() => {
			gameManageController.removePlayer(orderID);
		}, [gameManageController, orderID]);

		const selectPosition = useCallback(() => {
			if (isLockout) {
				return;
			}

			if (!hasPlayer) {
				gameManageController.selectPositionID(orderID);
			}
		}, [gameManageController, orderID, hasPlayer, isLockout]);

		const handleOver = (e: React.DragEvent<HTMLDivElement>) => {
			e.preventDefault();
		};

		const handleDragClick = () => {
			setIsClicked(true);
		};

		const openPlayerModal = () => {
			playerModalManager.openModal(userPickedPlayer?.id);
		};

		const isDisabled = hasPlayer || isLockout;
		const className = orderID === 1 ? "step-1 step-5" : "";

		return (
			<React.Fragment>
				<StyledDraftItem
					className={className}
					draggable={hasPlayer && isClicked}
					onDragOver={handleOver}
					onDragEnd={() => gameManageController.clearDrag()}
					onDrop={() => gameManageController.onDragEnd(orderID)}
					onDragStart={() =>
						gameManageController.onDragStart(orderID)
					}
					onClick={selectPosition}
					isSelected={isSelected}
					isDisabled={isDisabled}
					isTradeAlert={isTradeAlert}
				>
					<DragItem
						hasPlayer={hasPlayer}
						index={orderID}
						onMouseDown={handleDragClick}
					/>
					<PlayerLogoWrapper>
						<SquadLogo squadID={squad?.id} />
						<PlayerNumber>{orderID}.</PlayerNumber>
					</PlayerLogoWrapper>
					<Exist when={hasPlayer}>
						<SPlayerNameWrapper>
							<Exist when={!!userPickedPlayer?.link}>
								<SUserPlayIcon
									onClick={openPlayerModal}
									src={playIcon}
									alt="play"
								/>
							</Exist>

							<PlayerName onClick={openPlayerModal}>
								{userPickedPlayer?.firstName}{" "}
								{userPickedPlayer?.lastName}
							</PlayerName>
							<PlayerPosition>
								{userPickedPlayer?.position}
							</PlayerPosition>
							<PlayerCollege>
								{userPickedPlayer?.college}
							</PlayerCollege>
						</SPlayerNameWrapper>
						<Exist when={!isLockout}>
							<ActionImageButton onClick={removePlayer}>
								<img src={closeActionImage} alt="x" />
							</ActionImageButton>
						</Exist>
						<Exist when={isLockout}>
							<Points points={score} />
						</Exist>
					</Exist>
					<Exist when={!hasPlayer}>
						<div>
							<TeamNeeds>
								{I18n.t("draft_page.team_needs")}:{" "}
								{squad?.teamNeeds.join(", ")}
							</TeamNeeds>
						</div>
						<Exist when={!isLockout}>
							<ActionImageButton>
								<img src={primaryPlus} alt="+" />
							</ActionImageButton>
						</Exist>
					</Exist>
				</StyledDraftItem>
				<Exist when={!!isTradeAlert}>
					<SAlertMessageWrapper>
						<SAlertIcon src={warningIcon} alt="warning" />
						<SAlertMessage>{tradeAlertMessage}</SAlertMessage>
					</SAlertMessageWrapper>
				</Exist>
			</React.Fragment>
		);
	}
);
