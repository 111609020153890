import React, {InputHTMLAttributes} from "react";
import styled from "styled-components";
import {inputStyle} from "views/components/Inputs/Input";
import down from "assets/img/icons/down_dark.svg";

const SelectWrapper = styled.div`
	position: relative;
	box-sizing: border-box;

	&:after {
		position: absolute;
		right: 12px;
		top: 6px;
		content: url(${down});
		cursor: pointer;
	}
`;

const UserSelect = styled.select`
	${inputStyle};
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	cursor: pointer;
`;

export const Select: React.FC<InputHTMLAttributes<HTMLSelectElement>> = (
	props
) => {
	return (
		<SelectWrapper>
			<UserSelect {...props}>{props.children}</UserSelect>
		</SelectWrapper>
	);
};
