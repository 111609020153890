export enum ConnextraType {
	HOME = "f2p_draftpredictor_homepage",
	LOGIN = "f2p_draftpredictor_loggedin",
	PICKEM_PAGE = "f2p_draftpredictor_pickempage",
	PICKS_CONFIRM = "f2p_draftpredictor_pickconfirm",
	SHARE_CLICK = "f2p_draftpredictor_shareclick",
	VIDEO_VIEW = "f2p_draftpredictor_videoview",
}

const CONNEXTRA_URL = `https://zz.connextra.com/dcs/tagController/tag/11935e1e2233/`;

export const createConnextraScriptTag = (
	type: ConnextraType,
	accountId?: number
) => {
	const elementName = "script";
	const SCRIPT_ID = "connextra-tagging";
	document.getElementById(SCRIPT_ID)?.remove();
	const src = `${CONNEXTRA_URL}${type}`;

	const url = new URL(src);

	if (accountId) {
		url.searchParams.append("AccountID", accountId.toString());
	}

	const script = document.createElement(elementName);
	script.id = SCRIPT_ID;
	script.src = url.toString();
	script.async = true;
	script.defer = true;

	const firstJS = document.getElementsByTagName(elementName)[0];
	firstJS.parentNode!.insertBefore(script, firstJS);

	return script;
};
