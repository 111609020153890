import React from "react";
import styled from "styled-components";
import Select from "react-select";
import {ValueType} from "react-select/src/types";
import blackDecorate from "assets/img/blackDecorate.jpg";

const SDropdownWrapper = styled.div`
	width: 100%;
	background: url(${blackDecorate});
	box-sizing: border-box;
	max-height: 60px;
	border-left: 1px solid #013369;
`;

export interface IDropdownOptions {
	value: string;
	label: string;
}

interface IInnerSelect {
	isFocused: boolean;
}

const styles = {
	control: (styles: Record<string, unknown>) => ({
		...styles,
		backgroundColor: "transparent",
		border: "none",
		padding: "12px 30px",
		outline: "none",
		boxShadow: "none",
		cursor: "pointer",

		"@media screen and (max-width: 860px)": {
			padding: "2px",
		},
	}),
	placeholder: (styles: Record<string, unknown>) => ({
		...styles,
		color: "#FFFFFF",
		opacity: "0.6",
	}),
	singleValue: (styles: Record<string, unknown>) => ({
		...styles,
		color: "#FFFFFF",
		lineHeight: "21px",
	}),
	option: (styles: Record<string, unknown>, {isFocused}: IInnerSelect) => ({
		...styles,
		color: isFocused ? "#26212d" : "#FFFFFF",
		fontSize: "14px",
		letterSpacing: "0",
		backgroundColor: isFocused ? "#e60048" : "#25202d",
		padding: "22px 30px",

		"&:hover": {
			cursor: "pointer",
			backgroundColor: "#e60048 !important",
			color: "#26212d",
		},
		"&:active": {
			backgroundColor: "#e60048 !important",
			color: "#26212d",
		},
	}),
	menuList: (styles: Record<string, unknown>) => ({
		...styles,
		padding: 0,
		margin: 0,
	}),
	menu: (styles: Record<string, unknown>) => ({
		...styles,
		padding: 0,
		margin: 0,
		borderRadius: 0,
	}),
	indicatorSeparator: (styles: Record<string, unknown>) => ({
		...styles,
		display: "none",
	}),
	indicatorsContainer: (styles: Record<string, unknown>) => ({
		...styles,
		color: "#FFFFFF",
	}),
};

interface IProps {
	options: IDropdownOptions[];
	onChange?: (value: ValueType<IDropdownOptions, false>) => void;
	value?: IDropdownOptions;
	defaultValue?: IDropdownOptions;
}

export const CustomSelect: React.FC<IProps> = ({
	options,
	onChange,
	defaultValue,
	value,
}) => {
	return (
		<SDropdownWrapper>
			<Select
				options={options}
				defaultValue={defaultValue}
				value={value}
				onChange={onChange}
				styles={styles}
				isSearchable={false}
				isClearable={false}
			/>
		</SDropdownWrapper>
	);
};
