import {
	filter,
	find,
	flow,
	identity,
	includes,
	inRange,
	isEmpty,
	isEqual,
	keyBy,
	negate,
	overEvery,
	partial,
	property,
	range,
	reduce,
	size,
	sum,
	take,
} from "lodash";
import {join, lowerCase, paths} from "lodash/fp";
import {
	action,
	computed,
	makeAutoObservable,
	observable,
	reaction,
	runInAction,
	toJS,
} from "mobx";
import {
	Broadcasters,
	JSONCollection,
	Lockout,
	Player,
	PoolPositions,
	Squad,
} from "modules/stores/Models";
import {
	Api,
	ApiError,
	ConnextraType,
	createConnextraScriptTag,
	IPicksSaveResponse,
	PersistStorage,
} from "modules/utils";
import {
	ANSWER_SCORES,
	IS_REGISTRATION_DISABLED,
	LoadState,
	MAX_LINEUP_SIZE,
	MAX_SQUADS_OUTPUT,
} from "modules/constant";
import {Auth} from "modules/stores/Controllers/Auth";
import {ErrorManager} from "modules/stores/Controllers/ErrorManager";
import {IApiResponse} from "modules/types/common";

const BONUS_POINTS_RANGE = [
	{start: 0, end: 2, score: 0},
	{start: 2, end: 5, score: 25},
	{start: 5, end: 8, score: 50},
	{start: 8, end: 11, score: 75},
	{start: 11, end: Number.MAX_SAFE_INTEGER, score: ANSWER_SCORES.ACCURATE},
];

export class GameManager {
	private static readonly lineupPlaceholder = range(1, MAX_LINEUP_SIZE + 1);
	private static readonly squadsListPlaceholder = range(
		1,
		MAX_SQUADS_OUTPUT + 1
	);

	@observable public selectedPositionID?: number;
	@observable public isVisibleShareModal = false;
	@observable public picksImageUrl = "";
	@observable public picksMobileImageUrl = "";
	@observable private userPicks: Map<number, number | null> = new Map<
		number,
		number | null
	>();
	@observable private clonedUserPicks: Map<number, number | null> = new Map<
		number,
		number | null
	>();
	@observable private filterByPosition: string = "";
	@observable public filterByName: string = "";
	@observable private saveStateRequest = LoadState.IDLE;
	@observable private dragItem?: number;
	@observable private picksCopy?: Record<number, number | null>;

	constructor(
		private _players: JSONCollection<Player>,
		private _squads: JSONCollection<Squad>,
		private _poolPositions: PoolPositions,
		private _authController: Auth,
		private _errorManager: ErrorManager,
		private _lockoutController: Lockout,
		private _broadcasters: Broadcasters
	) {
		makeAutoObservable(this);

		void this._players.request();
		void this._squads.request();
		void this._broadcasters.request();

		this.prefillSelectionsFromStorage();

		reaction(
			() => this.pickedPlayersIDs,
			() => {
				if (!this._authController.isLoggedIn) {
					this.savePicksToStorage();
				}
			}
		);

		reaction(
			() => _authController.isLoggedIn && _authController.isRecovered,
			(isLoggedInAndRecovered) => {
				if (isLoggedInAndRecovered) {
					if (this.pickedSize) {
						/**
						 * On Success login we call save selections from local storage if user has smth.
						 */
						void this.savePicks();
					} else {
						/**
						 * Otherwise, if selections are empty on login, we call show selections from API
						 */
						void this.showPicks();
					}
				} else {
					/**
					 * Remove selections when user logged out
					 */
					this.userPicks.clear();
					PersistStorage.REMOVE("lineup");
				}
			}
		);
	}

	@action
	public clonePicks() {
		this.clonedUserPicks = new Map(this.userPicks);
	}

	@action
	public restorePicks() {
		this.userPicks = new Map(this.clonedUserPicks);
	}

	private isArraySame(
		array1: unknown[] | undefined,
		array2: unknown[]
	): boolean {
		if (!array1 || !array2) {
			return true;
		}
		return (
			array1.length === array2.length &&
			array1.every((value, index) => value === array2[index])
		);
	}

	private static isEmptyArray(array: unknown[] | undefined): boolean {
		if (!array) {
			return true;
		}
		return array.every((opt) => opt === null);
	}

	private static isNotFullArray(array: unknown[] | undefined): boolean {
		if (!array) {
			return true;
		}

		return array.some((opt) => opt === null);
	}

	@computed get isPlayerPickSame() {
		let userPicks;
		if (this.picksCopy) {
			userPicks = Object.values(
				this.picksCopy as {[key: number]: number}
			);
		}
		const picks = [...this.lineup].map((item) => item.userPickedPlayer?.id);
		return (
			this.isArraySame(userPicks, picks) ||
			GameManager.isEmptyArray(userPicks) ||
			GameManager.isNotFullArray(userPicks)
		);
	}

	@computed
	public get isSavingPicks() {
		return isEqual(this.saveStateRequest, LoadState.Requested);
	}

	private prefillSelectionsFromStorage = () => {
		const picks = JSON.parse(PersistStorage.GET("lineup")!) as Record<
			string,
			number
		>;

		if (picks) {
			this.setPicksToModel(picks);
		}
	};

	@action public savePicks = (methodName: "save" | "share" = "save") => {
		if (
			this._lockoutController.isLockout ||
			this._lockoutController.isDraftEnd
		) {
			return;
		}

		this.saveStateRequest = LoadState.Requested;
		void Api.Picks[methodName]<IPicksSaveResponse>({
			picks: this.lineupJSON,
		})
			.then(this.setSelectionFromResponse)
			.then(this.handleSuccessSave)
			.catch(this.handleNetworkError)
			.finally(() =>
				runInAction(() => {
					this.saveStateRequest = LoadState.Received;
				})
			);
	};

	public showPicks = () => {
		Api.Picks.show<IPicksSaveResponse>()
			.then(this.setSelectionFromResponse)
			.catch(this.handleNetworkError);
	};

	private handleNetworkError = (response: ApiError) => {
		this._errorManager.setError(response.message);
	};

	@action
	private setPicksToModel(picks: Record<number, number | null>) {
		this.picksCopy = picks;
		for (const key in picks) {
			const playerID = picks[key];

			if (playerID) {
				this.userPicks.set(Number(key), playerID);
			}
		}
	}

	private setSelectionFromResponse = (
		response: IApiResponse<IPicksSaveResponse>
	) => {
		const picks = response.result?.picks;
		this.picksImageUrl = response.result?.imageLink || "";
		this.picksMobileImageUrl = response.result?.mobileImageLink || "";

		if (picks) {
			this.setPicksToModel(picks);
		}
	};

	private handleSuccessSave = () => {
		this.showShareModal();

		if (this._authController.user?.id) {
			createConnextraScriptTag(
				ConnextraType.PICKS_CONFIRM,
				this._authController.user.id
			);
		}

		// Save user selections for syndicates without registration
		if (!IS_REGISTRATION_DISABLED) {
			PersistStorage.REMOVE("lineup");
		}
	};

	@action private showShareModal() {
		this.isVisibleShareModal = true;
	}

	@action public hideShareModal = () => {
		this.isVisibleShareModal = false;
	};

	@computed get lineupJSON() {
		return reduce(
			GameManager.lineupPlaceholder,
			(acc: Record<number, number | null>, position) => {
				const pickedUser = this.userPicks.get(position);
				acc[position] = pickedUser || null;
				return acc;
			},
			{}
		);
	}

	@action
	public setFilterByPositionValue(value: string) {
		this.filterByPosition = value;
	}

	@action
	public get filterByPositionValue() {
		return this.filterByPosition;
	}

	@action
	public setFilterByNameValue(value: string) {
		this.filterByName = value;
	}

	@action
	public selectPositionID(positionID: number) {
		const isSamePosition = positionID === this.selectedPositionID;
		this.selectedPositionID = isSamePosition ? undefined : positionID;
	}

	@action
	public clearSelectPositionId() {
		this.selectedPositionID = undefined;
	}

	@action public addPlayer = (playerID: number) => {
		if (!this.availablePosition) {
			return;
		}

		const position =
			this.selectedPositionID &&
			!this.userPicks.get(this.selectedPositionID)
				? this.selectedPositionID
				: this.availablePosition;

		this.userPicks.set(position, playerID);
		this.selectedPositionID = undefined;
	};

	public movePlayer(fromIndex: number, toIndex: number): void {
		const userFromIndex = this.userPicks.get(fromIndex);
		this.userPicks.set(fromIndex, this.userPicks.get(toIndex) || null);
		this.userPicks.set(toIndex, userFromIndex || null);
	}

	private savePicksToStorage() {
		PersistStorage.SET("lineup", this.lineupJSON);
	}

	@action public removePlayer = (orderID: number) => {
		this.userPicks.delete(orderID);
	};

	@computed
	private get availablePosition() {
		return GameManager.lineupPlaceholder.find(
			(orderID) => !this.userPicks.get(orderID)
		);
	}

	@computed
	public get squadsGroupedByOrder() {
		const squadsWithOrder = filter(
			this._squads.entities,
			(squads) => !isEmpty(squads.pickOrder)
		);

		return reduce<number, Map<number, Squad | undefined>>(
			GameManager.squadsListPlaceholder,
			(acc, id) =>
				acc.set(
					id,
					squadsWithOrder.find(({pickOrder}) =>
						includes(pickOrder, id)
					)
				),
			new Map()
		);
	}

	@computed
	private get playersByPick() {
		return keyBy(this._players.entities, "pick");
	}

	@computed get lineup() {
		return take(this.draftOrder, MAX_LINEUP_SIZE).map((pick, index) => ({
			...pick,
			score: this.getPredictionScore(
				index,
				pick.userPickedPlayer?.id,
				pick.squadPickedPlayer?.id
			),
		}));
	}

	@computed
	private get pickedPlayersID() {
		return take(this.draftOrder, MAX_LINEUP_SIZE + 2).map(
			(pick) => pick.squadPickedPlayer?.id
		);
	}

	private getPredictionScore(
		index: number,
		userPickPlayerID?: number,
		squadPickPlayerID?: number
	) {
		if (!userPickPlayerID) {
			return null;
		}

		if (userPickPlayerID === squadPickPlayerID) {
			return ANSWER_SCORES.ACCURATE;
		}

		const getPlayersIDSpotAway = (awaySpots: number) => [
			this.draftOrder[index - awaySpots]?.squadPickedPlayer?.id,
			this.draftOrder[index + awaySpots]?.squadPickedPlayer?.id,
		];

		if (getPlayersIDSpotAway(1).includes(userPickPlayerID)) {
			return ANSWER_SCORES.ONE_PICK_AWAY;
		}

		if (getPlayersIDSpotAway(2).includes(userPickPlayerID)) {
			return ANSWER_SCORES.TWO_PICKS_AWAY;
		}

		if (this.lastPickedIndex > index + 1) {
			return ANSWER_SCORES.INCORRECT;
		}

		return this.pickedPlayersID.includes(userPickPlayerID)
			? ANSWER_SCORES.INCORRECT
			: null;
	}

	@computed get lastPickedIndex() {
		return (
			this.draftOrder.filter((pick) => Boolean(pick.squadPickedPlayer))
				.length - 1
		);
	}

	@computed
	hasChanges(): boolean {
		return false;
	}

	@computed get totalPoints() {
		const scoresList = this.lineup.map(property("score")).filter(identity);
		const totalAnswerPoints = sum(scoresList);
		const perfectPicksSize = size(
			scoresList.filter((points) => points === ANSWER_SCORES.ACCURATE)
		);

		const bonusPoints = find(BONUS_POINTS_RANGE, (range) =>
			inRange(perfectPicksSize, range.start, range.end)
		);

		return totalAnswerPoints + (bonusPoints?.score || 0);
	}

	@computed
	public get draftOrder() {
		return GameManager.squadsListPlaceholder.map((orderID) => {
			const pickedPlayerID = this.userPicks.get(orderID);
			const squadPickedPlayer = this.playersByPick[orderID];

			return {
				orderID,
				squad: this.squadsGroupedByOrder.get(orderID),
				userPickedPlayer: pickedPlayerID
					? this._players.byID[pickedPlayerID]
					: undefined,
				squadPickedPlayer: squadPickedPlayer
					? squadPickedPlayer
					: undefined,
			};
		});
	}

	@computed get pickedPlayersIDs() {
		return Array.from(this.userPicks.values()).filter(identity);
	}

	@computed get pickedSize() {
		return size(this.pickedPlayersIDs);
	}

	@computed get isAllPositionsFilled() {
		return isEqual(this.pickedSize, MAX_LINEUP_SIZE);
	}

	@computed get filtersPredicate() {
		const availablePlayersOnly = flow([
			property("id"),
			negate(partial(includes, this.pickedPlayersIDs)),
		]);

		const predicates = [availablePlayersOnly];

		if (this.filterByName) {
			const hasSearchNameReference = flow([
				paths(["firstName", "lastName"]),
				join(" "),
				lowerCase,
				partial(
					includes,
					partial.placeholder,
					lowerCase(this.filterByName)
				),
			]);

			predicates.push(hasSearchNameReference);
		}

		if (this.filterByPosition) {
			const positionsGroup =
				this._poolPositions.byShortName[this.filterByPosition].groups;

			const equalToSelectedPosition = flow([
				property("position"),
				partial(includes, positionsGroup),
			]);

			predicates.push(equalToSelectedPosition);
		}

		return overEvery(predicates);
	}

	@computed
	public get players() {
		return filter(this._players.entities, this.filtersPredicate);
	}

	@action
	public onDragStart(item: number): void {
		this.dragItem = item;
	}

	@action
	public onDragEnd(toItem: number): void {
		if (this.dragItem === toItem) {
			this.clearDrag();
			return;
		}

		this.movePlayer(Number(this.dragItem), toItem);
	}

	@action
	public clearDrag(): void {
		this.dragItem = undefined;
	}

	@computed
	public get broadcasters() {
		return toJS(this._broadcasters.data);
	}
}
