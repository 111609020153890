import React from "react";
import styled from "styled-components";
import {Container} from "views/components/Container";
import {NavLink} from "react-router-dom";
import geniusLogo from "assets/img/geniusLogo.svg";
import {ExternalLink} from "views/components/Link";
import {observer} from "mobx-react";
import {useStore} from "modules/hooks";
import {Exist} from "views/components/Exist";
import adLogo from "assets/img/logo-patrocinador-apuestas-white.png";

const Wrapper = styled.footer`
	background: #151515;
`;

const Inner = styled.div`
	padding: 35px 0 80px;
	text-align: center;

	@media (max-width: 1000px) {
		padding-bottom: 40px;
	}

	@media screen and (max-width: 860px) {
		margin-bottom: 52px;
	}
`;

const TermsLink = styled(NavLink)`
	text-decoration: underline;
	color: #ffffff;
	font-size: 14px;
	line-height: 22px;

	&:hover {
		text-decoration: none;
	}
`;

const TermsBlock = styled.p`
	margin-bottom: 40px;

	@media (max-width: 1000px) {
		margin-bottom: 25px;
	}
`;

const CopyrightBlock = styled.p`
	color: #8e8e93;
	font-size: 12px;
	line-height: 22px;
	max-width: 940px;
	padding: 0 20px;
	box-sizing: border-box;
	display: block;
	margin: 0 auto 30px;

	@media (max-width: 1000px) {
		margin-bottom: 25px;
	}
`;
const SportLogo = styled.a`
	padding: 8px;
	width: 120px;
	height: 50px;
	display: flex;
	margin: 0 auto;
	background-image: url(${adLogo});
	background-size: contain;
	background-repeat: no-repeat;
`;

export const Footer: React.FC = observer(() => {
	const {I18n, SEOManager} = useStore();
	return (
		<Wrapper>
			<Container>
				<Inner>
					<TermsBlock>
						<Exist when={SEOManager.isShowEightLogo}>
							<SportLogo href={SEOManager.getAdLink} />
						</Exist>
						<TermsLink to="/help/terms">
							{I18n.t("footer.terms")}
						</TermsLink>
					</TermsBlock>
					<CopyrightBlock>
						© 2023 NFL Enterprises LLC. NFL and the NFL shield
						design are registered trademarks of the National
						Football League.The team names, logos and uniform
						designs are registered trademarks of the teams
						indicated. All other NFL-related trademarks are
						trademarks of the National Football League. NFL footage
						© NFL Productions LLC.
					</CopyrightBlock>
					<ExternalLink href="https://geniussports.com/">
						<img src={geniusLogo} alt="Genius Sports" />
					</ExternalLink>
				</Inner>
			</Container>
		</Wrapper>
	);
});
