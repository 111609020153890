import {HTTPClient} from "@fanhubmedia/fe-common-utils";
import {AxiosError} from "axios";
import {
	API_URL,
	JSON_URL,
	Language,
	LOCALE,
	Order,
	SHARE_URI,
	SYNDICATE,
} from "modules/constant";
import {ApiError} from "./ApiError";
import {IApiResponse} from "modules/types/common";
import {IContact} from "modules/stores/Controllers/Contact";

const onCatchNetworkError = (response: AxiosError) =>
	ApiError.CHECK(response.response?.data ?? response);

const APIClient = new HTTPClient({
	baseURL: API_URL,
	onCatchNetworkError,
	withCredentials: true,
});

const PureAPIClient = new HTTPClient({
	baseURL: SHARE_URI,
	onCatchNetworkError,
	withCredentials: true,
});

const JSONClient = new HTTPClient({
	baseURL: JSON_URL,
	onCatchNetworkError,
	onBeforeRequest: (requestConfig) => {
		requestConfig.params = {t: Date.now()};
	},
});

export interface IAPILogin {
	email: string;
	gigyaId: string;
	gigyaSignature: string;
	gigyaSignatureTimestamp: string;
}

export interface IGigyaResponse extends IAPILogin {
	firstName: string;
	lastName: string;
	birthDay: number;
	birthYear: number;
	birthMonth: number;
	country: string;
}

export interface IAPIUserCreate extends IGigyaResponse {
	language: Language;
	notificationsEnabled: boolean;
	username: string;
}

export interface IPicksSaveResponse {
	picks: Record<number, number | null>;
	imageLink?: string;
	mobileImageLink?: string;
}

export interface ILeaderBoardRequestParams {
	offset: number;
	limit: number;
	order: Order;
}

export const Api = {
	User: {
		create: <T>(params: IAPIUserCreate) =>
			APIClient.post<IApiResponse<T>>(`${SYNDICATE}/user/create`, params),
		recover: <T>() =>
			APIClient.post<IApiResponse<T>>(`${SYNDICATE}/user/recover`),
	},
	Auth: {
		sso_login: <T>(params: IAPILogin) =>
			APIClient.post<IApiResponse<T>>(
				`${SYNDICATE}/auth/sso/login`,
				params
			),
		sso_logout: <T>() =>
			APIClient.post<IApiResponse<T>>(`${SYNDICATE}/auth/logout`),
	},
	JSON: {
		checksums: <T>() => JSONClient.get<T>("checksums.json"),
		trade_alerts: <T>() => JSONClient.get<T>("trade_alerts.json"),
		squads: <T>() => JSONClient.get<T>("squads.json"),
		players: <T>() => JSONClient.get<T>("players.json"),
		broadcasters: <T>() => JSONClient.get<T>("broadcasters.json"),
		lang: <T>() => JSONClient.get<T>(`langs/${LOCALE}.json`),
		loadHelpTranslationForPage: <T>(page: string) =>
			JSONClient.get<T>(`${SYNDICATE}/${page}.json`),
	},
	Picks: {
		save: <T>(params: IPicksSaveResponse) =>
			APIClient.post<IApiResponse<T>>(`${SYNDICATE}/picks/save`, params),
		show: <T>() =>
			APIClient.get<IApiResponse<T>>(`${SYNDICATE}/picks/show`),
		share: <T>(params: IPicksSaveResponse) =>
			APIClient.post<IApiResponse<T>>(`${SYNDICATE}/picks/share`, params),
	},
	Lockout: {
		lockout: <T>() =>
			APIClient.get<IApiResponse<T>>(`${SYNDICATE}/lockout`),
	},
	LeaderBoard: {
		leaderBoards: <T>(params: ILeaderBoardRequestParams) =>
			APIClient.get<T>(`${SYNDICATE}/leaderboard`, params),
	},
	Share: {
		shareFacebook: <T>(params: string) =>
			PureAPIClient.get<IApiResponse<T>>(`share/${SYNDICATE}/${params}`),
	},
	Contact: {
		contact: <T>(params: IContact) =>
			APIClient.post<T>(`${SYNDICATE}/contact`, params),
	},
};

export * from "./ApiError";
