import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {LoadState} from "modules/constant";
import {keyBy, size} from "lodash";

export class JSONCollection<TModel> {
	@observable apiState: LoadState = LoadState.IDLE;
	@observable error?: string;
	@observable entities: TModel[] = [];

	@computed
	public get size() {
		return size(this.entities);
	}

	@computed
	public get byID() {
		return keyBy(this.entities, "id");
	}

	constructor(
		protected readonly Model: {new (args: TModel): TModel},
		protected readonly api: <TReturn extends unknown, TArgs = void>(
			args?: TArgs
		) => Promise<TReturn>
	) {
		makeObservable(this);
	}

	@action
	async request() {
		if (this.apiState === LoadState.Requested) {
			return;
		}

		this.apiState = LoadState.Requested;
		this.error = undefined;

		try {
			const response = await this.api<TModel[]>();

			runInAction(() => {
				this.entities = response.map(
					(entity) => new this.Model(entity)
				);
				this.apiState = LoadState.Received;
			});
		} catch (err) {
			this.entities = [];
			this.apiState = LoadState.IDLE;
			this.error = (err as Error).message;
		}
	}
}
