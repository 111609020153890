import React from "react";
import {observer} from "mobx-react";
import {useStore} from "modules/hooks";
import styled from "styled-components";
import Slider from "react-slick";
import "assets/css/slick.css";
import {BROADCASTER_IMAGE, SYNDICATE} from "modules/constant";
import {ModalBodyHeading} from "views/components/Modals/ModalBase";
import {SyndicatesType} from "modules/enums";
import {get} from "lodash";

const mobileBreakpoint = "600px";
const Wrapper = styled.div`
	color: #fff;
	width: 150px;
	text-align: center;
	height: 115px;
	margin: 0 20px;

	h2 {
		margin-top: 10px;
		font-size: 16px;
		line-height: 18px;
		font-weight: bold;
		@media screen and (max-width: ${mobileBreakpoint}) {
			display: none;
		}
	}
	@media screen and (min-width: ${mobileBreakpoint}) {
		padding: 0 5px;
		box-sizing: border-box;
		border: 1px solid #757575;
		background-color: #333333;
		z-index: 1;
	}
	@media screen and (max-width: ${mobileBreakpoint}) {
		height: 100%;
		width: 100px;
		margin-left: auto;
	}
`;
const BroadCast = styled.div`
	height: 60px;
	@media screen and (max-width: ${mobileBreakpoint}) {
		height: 100%;
	}
	a {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		img {
			max-width: 100%;
			max-height: 50px;
		}
	}
`;
export const BroadCasters: React.FC = observer(() => {
	const {I18n, gameManageController} = useStore();
	const settings = {
		dots: false,
		infinite: true,
		arrows: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		swipeToSlide: false,
		autoplaySpeed: 8000,
		autoplay: true,
	};

	return (
		<Wrapper>
			<h2>{I18n.t("subheader.broadcast_partner.title")}</h2>
			<Slider {...settings}>
				{gameManageController.broadcasters.map((broadcaster) => {
					return (
						<BroadCast key={broadcaster.broadcaster}>
							<a
								href={broadcaster.video}
								target="_blank"
								rel="noreferrer"
							>
								<img
									src={BROADCASTER_IMAGE.get(
										broadcaster.broadcaster
									)}
									alt={broadcaster.broadcaster}
								/>
							</a>
						</BroadCast>
					);
				})}
			</Slider>
		</Wrapper>
	);
});

const Images = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	a {
		max-width: 100px;
		img {
			width: auto;
			max-width: 100%;
		}
	}
`;

const dates = {
	[SyndicatesType.DE]: [
		{id: 1, date: " 28 - 00:30 Uhr"},
		{id: 2, date: " 29 - 00:30 Uhr"},
		{id: 3, date: " 29 - 06:00 Uhr"},
	],
	[SyndicatesType.MX]: [
		{id: 1, date: " 27 - 06:00 PM"},
		{id: 2, date: " 28 - 05:00 PM"},
		{id: 3, date: " 29 - 10:00 AM"},
	],
	[SyndicatesType.CA]: [
		{id: 1, date: " 27 - 08:00 PM"},
		{id: 2, date: " 28 - 07:00 PM"},
		{id: 3, date: " 29 - 12:00 PM"},
	],
	[SyndicatesType.UK]: [
		{id: 1, date: " 28 - 01:00 AM"},
		{id: 2, date: " 29 - 01:30 AM"},
		{id: 3, date: " 29 - 05:00 PM"},
	],
};

interface IDate {
	id: number;
	date: string;
}

export const BroadCastInfo = () => {
	const {I18n, gameManageController} = useStore();
	const syndicateDates = get(
		dates,
		SYNDICATE.toUpperCase(),
		dates[SyndicatesType.CA]
	) as IDate[];

	return (
		<div>
			<div>
				<ModalBodyHeading>
					{I18n.t("modals.share.watch_sub_title")}
				</ModalBodyHeading>

				{syndicateDates.map(({id, date}: IDate) => {
					return (
						<ModalBodyHeading key={id}>
							{I18n.t(`modals.share.watch.day_${id}`)} {date}
						</ModalBodyHeading>
					);
				})}
				<Images>
					{gameManageController.broadcasters.map((broadcaster) => {
						return (
							<a
								key={broadcaster.broadcaster}
								href={broadcaster.video}
								target="_blank"
								rel="noreferrer"
							>
								<img
									src={BROADCASTER_IMAGE.get(
										broadcaster.broadcaster
									)}
									alt={broadcaster.broadcaster}
								/>
							</a>
						);
					})}
				</Images>
			</div>
		</div>
	);
};
