import React from "react";
import styled from "styled-components";
import closeIcon from "assets/img/icons/close.svg";
import {PureButton} from "views/components/Button";
import {Exist} from "views/components/Exist";

export const ModalContainerStyled = styled.div`
	max-width: 600px;
	width: calc(100% - 24px);
	padding: 0;
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background: #ffffff;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	max-height: calc(100vh - 48px);
	display: flex;
	flex-direction: column;
	z-index: 1001;
`;

const CloseButton = styled(PureButton)`
	position: absolute;
	top: 20px;
	right: 20px;
`;

const CloseButtonForWhite = styled(CloseButton)`
	right: 0;
	top: -24px;
`;

interface IProps {
	showClose?: boolean;
	onCloseClick?: () => void;
	isWhiteModal?: boolean;
}

export const ModalContainer: React.FC<IProps> = ({
	onCloseClick,
	showClose = false,
	isWhiteModal = false,
	children,
}) => (
	<ModalContainerStyled>
		<Exist when={showClose}>
			<Exist when={!isWhiteModal}>
				<CloseButton onClick={onCloseClick}>
					<img src={closeIcon} alt="close" />
				</CloseButton>
			</Exist>
			<Exist when={isWhiteModal}>
				<CloseButtonForWhite onClick={onCloseClick}>
					<img src={closeIcon} alt="close" />
				</CloseButtonForWhite>
			</Exist>
		</Exist>
		{children}
	</ModalContainerStyled>
);
