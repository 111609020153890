import React, {InputHTMLAttributes} from "react";
import styled, {css} from "styled-components";
import {Tooltip} from "views/components";

export const inputStyle = css`
	border: 1px solid #cccccc;
	border-radius: 4px;
	background-color: #ffffff;
	width: 100%;
	box-sizing: border-box;

	color: #757575;
	font-size: 14px;
	letter-spacing: 0;
	padding: 9px 16px;
	position: relative;
	margin-bottom: 20px;
`;

const InputWrapper = styled.div`
	position: relative;
	box-sizing: border-box;
`;

const UserInput = styled.input`
	${inputStyle};
`;

export const TooltipWrapper = styled.div`
	position: absolute;
	top: 10px;
	right: 12px;
`;

export const Input: React.FC<InputHTMLAttributes<HTMLInputElement>> = (
	props
) => {
	return (
		<InputWrapper>
			<UserInput {...props} />
			<TooltipWrapper>
				<Tooltip>
					<p>Info</p>
				</Tooltip>
			</TooltipWrapper>
		</InputWrapper>
	);
};
