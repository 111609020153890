import React from "react";
import styled from "styled-components";
import {observer} from "mobx-react";
import {useStore} from "modules/hooks";
import {Exist} from "views/components/Exist";
import adItemBanner from "assets/img/adMainBanner.png";

const SADContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 35px 24px;
	box-sizing: border-box;

	@media screen and (max-width: 860px) {
		padding: 15px 20px;
	}
`;

const SFakeAd = styled.a`
	max-width: 728px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 24px;
	height: 90px;
	color: transparent;
	background-image: url(${adItemBanner});
	background-size: 100% 100%;
	box-sizing: border-box;
	background-repeat: no-repeat;

	@media screen and (max-width: 860px) {
		height: 50px;
	}
`;

export const ADItem: React.FC = observer(() => {
	const {SEOManager} = useStore();
	return (
		<SADContainer>
			<Exist when={SEOManager.isShowAd}>
				<SFakeAd href={SEOManager.getAdLink} />
			</Exist>
		</SADContainer>
	);
});
