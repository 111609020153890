import {ApiModel} from "./ApiModel";
import {action, computed, makeObservable, observable} from "mobx";
import {IApiResponse} from "modules/types/common";
import moment, {Moment} from "moment";
import {filter, gte, matches, negate, padStart, size} from "lodash";
import {JSONCollection} from "modules/stores/Models/JSONCollection";
import {Player} from "modules/stores/Models/Player";
import {MAX_SQUADS_OUTPUT} from "modules/constant";

export interface ILockoutResponse {
	secondsToLockout: number;
}

type TResponse = IApiResponse<ILockoutResponse>;

export class Lockout extends ApiModel<TResponse> {
	@observable private currentDate = moment();
	@observable private lockoutAt?: Moment;
	@observable private timerInterval?: ReturnType<typeof setInterval>;

	constructor(
		protected readonly api: () => Promise<TResponse>,
		private _players: JSONCollection<Player>
	) {
		super(api);
		makeObservable(this);
		void this.request();
	}

	@computed public get isLockout() {
		if (!this.lockoutAt) {
			return false;
		}

		return this.diff <= 0;
	}

	@computed public get isDraftEnd() {
		const pickedPlayers = filter(
			this._players.entities,
			negate(matches({pick: 0}))
		);
		return gte(size(pickedPlayers), MAX_SQUADS_OUTPUT);
	}

	@computed private get diff() {
		return this.lockoutAt?.diff(this.currentDate) || 0;
	}

	@computed public get time() {
		const duration = moment.duration(this.diff, "millisecond");

		return {
			days: padStart(String(duration.days()), 2, "0"),
			hours: padStart(String(duration.hours()), 2, "0"),
			minutes: padStart(String(duration.minutes()), 2, "0"),
		};
	}

	@action private tick(newDate = moment()) {
		this.currentDate = newDate;

		if (this.isLockout) {
			this.stopCounter();
		}
	}

	@action protected performResponse(resp: TResponse): void {
		this.stopCounter();
		this.lockoutAt = moment().add(resp.result!.secondsToLockout, "second");
		this.tick();

		this.timerInterval = setInterval(() => {
			this.tick();
		}, 1000 * 15);
	}

	@action private stopCounter() {
		clearInterval(this.timerInterval!);
	}

	protected onApiError(err: Error): void {
		console.log(err);
	}
}
