import {action, makeObservable, observable} from "mobx";
import {ApiModel} from "./ApiModel";

type TResponse = Record<number, string>;

export class TradesAlert extends ApiModel<TResponse> {
	@observable public data: Record<string, string> = {};

	constructor(protected readonly api: () => Promise<TResponse>) {
		super(api);
		makeObservable(this);
	}

	@action protected performResponse(response: TResponse) {
		this.data = response;
	}

	protected onApiError(err: Error): void {
		console.log(err);
	}
}
