import styled from "styled-components";

export const ModalOverlay = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.8);
	z-index: 1000;
`;
