import React from "react";
import {useStore} from "modules/hooks";
import {
	ModalBody,
	ModalBodyText,
	ModalContainer,
	ModalHeader,
	ModalOverlay,
} from "views/components/Modals/ModalBase";
import {CTAButton} from "views/components/Button";
import styled from "styled-components";
import {observer} from "mobx-react";
import {useHistory} from "react-router-dom";

const SButtonsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	> ${CTAButton} {
		width: calc(50% - 6px);
	}
`;

const SecondaryButton = styled(CTAButton)`
	background: #d1d1d1;
	color: #ffffff;
	border-color: #d1d1d1;
`;

export const ConfirmationModal: React.FC = observer(() => {
	const {confirmationManager, I18n} = useStore();
	const history = useHistory();

	if (!confirmationManager.isModalOpen) {
		return null;
	}

	const redirect = () => {
		if (confirmationManager.location) {
			confirmationManager.closeModal(true);
			history.push(confirmationManager.location);
			confirmationManager.clearLocation();
		}
	};

	return (
		<React.Fragment>
			<ModalOverlay />
			<ModalContainer>
				<ModalHeader />
				<ModalBody>
					<ModalBodyText>
						{I18n.t("modals.warning.body")}
					</ModalBodyText>
					<SButtonsWrapper>
						<CTAButton
							onClick={() =>
								confirmationManager.closeModal(false)
							}
						>
							{I18n.t("modals.warning.no")}
						</CTAButton>
						<SecondaryButton onClick={redirect}>
							{I18n.t("modals.warning.yes")}
						</SecondaryButton>
					</SButtonsWrapper>
				</ModalBody>
			</ModalContainer>
		</React.Fragment>
	);
});
