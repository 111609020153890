import {action, makeObservable, observable} from "mobx";
import {ApiModel} from "./ApiModel";

interface IChecksumsResponse {
	squads: string;
	players: string;
	trade_alerts: string;
}

export class Checksums extends ApiModel<IChecksumsResponse> {
	@observable public data?: IChecksumsResponse;

	constructor(protected readonly api: () => Promise<IChecksumsResponse>) {
		super(api);
		makeObservable(this);
		void this.request();
	}

	@action protected performResponse(response: IChecksumsResponse) {
		this.data = response;
	}

	protected onApiError(err: Error): void {
		console.log(err);
	}
}
