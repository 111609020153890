import React, {useCallback, useEffect} from "react";
import {ModalContainer, ModalOverlay} from "views/components/Modals/ModalBase";
import styled from "styled-components";
import {CTAButtonUppercase} from "views/components/Button";
import {useStore} from "modules/hooks";
import {observer} from "mobx-react";
import {Exist} from "views/components/Exist";
import {ConnextraType, createConnextraScriptTag} from "modules/utils";

const SPlayerModalHeader = styled.div`
	padding: 38px 26px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const SPlayerName = styled.h4`
	color: #333333;
	font-size: 20px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 28px;
`;

const SPlayerData = styled.span`
	color: #757575;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0;

	&:first-of-type {
		margin-right: 10px;
	}
`;

const SVideoWrapper = styled.div`
	overflow: hidden;
	min-height: 300px;
	width: 100%;
	background: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-border-radius: 0 0 4px 4px;
	-moz-border-radius: 0 0 4px 4px;
	border-radius: 0 0 4px 4px;
`;

const CTAButtonUppercaseStyled = styled(CTAButtonUppercase)`
	width: auto;
`;

export const PlayerModal: React.FC = observer(() => {
	const {
		playerModalManager,
		gameManageController,
		I18n,
		lockoutController,
		authController,
	} = useStore();
	const {isLockout} = lockoutController;

	const isPlayerSelected = gameManageController.pickedPlayersIDs.includes(
		playerModalManager.playerData?.id
	);

	const closeModal = useCallback(() => {
		playerModalManager.closeModal();
	}, [playerModalManager]);

	const addPlayer = useCallback(() => {
		const playerId = playerModalManager.playerData.id;
		gameManageController.addPlayer(playerId);
		playerModalManager.closeModal();
	}, [gameManageController, playerModalManager]);

	useEffect(() => {
		if (authController.user?.id && playerModalManager.isModalOpen) {
			createConnextraScriptTag(
				ConnextraType.VIDEO_VIEW,
				authController.user.id
			);
		}
	}, [authController, playerModalManager]);

	if (!playerModalManager.isModalOpen) {
		return null;
	}

	return (
		<React.Fragment>
			<ModalOverlay onClick={() => playerModalManager.closeModal()} />
			<ModalContainer
				showClose={true}
				isWhiteModal={true}
				onCloseClick={closeModal}
			>
				<SPlayerModalHeader>
					<div>
						<SPlayerName>
							{playerModalManager.playerData.firstName}
							&nbsp;
							{playerModalManager.playerData.lastName}
						</SPlayerName>
						<SPlayerData>
							{playerModalManager.playerData.position}
						</SPlayerData>
						<SPlayerData>
							{playerModalManager.playerData.college}
						</SPlayerData>
					</div>
					<Exist
						when={
							!gameManageController.isAllPositionsFilled &&
							!isPlayerSelected &&
							!isLockout
						}
					>
						<CTAButtonUppercaseStyled onClick={addPlayer}>
							{I18n.t("modals.player.draft")}
						</CTAButtonUppercaseStyled>
					</Exist>
				</SPlayerModalHeader>
				<SVideoWrapper>
					<iframe
						src={playerModalManager.playerData.link}
						width="100%"
						height="500px"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					/>
				</SVideoWrapper>
			</ModalContainer>
		</React.Fragment>
	);
});
