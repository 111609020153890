import React, {useCallback, useEffect} from "react";
import styled from "styled-components";
import {
	BroadCasters,
	DraftItem,
	DraftsCounter,
	Exist,
	PureButton,
} from "views/components";
import {useMediaQuery, useStore} from "modules/hooks";
import {observer} from "mobx-react";
import {Prompt} from "react-router";
import * as H from "history";
import {ReactComponent as InfoIcon} from "assets/img/icons/infoWhite.svg";
import blackDecorate from "assets/img/blackDecorate.jpg";

const StyledSidebar = styled.div`
	width: 400px;
	min-width: 400px;

	@media screen and (max-width: 1000px) {
		width: 100vw;
		min-width: 300px;
		max-width: 300px;
	}

	@media screen and (max-width: 860px) {
		max-width: 100%;
		min-width: 100%;
	}
`;

const StyledSidebarHeader = styled.div`
	background: url(${blackDecorate}) center;
	color: #ffffff;
	font-size: 16px;
	font-family: "AllProSans", sans-serif;
	line-height: 24px;
	letter-spacing: 0;
	padding: 22px 34px;
	max-height: 60px;
	display: flex;
	align-items: center;
	gap: 10px;
	box-sizing: border-box;

	${PureButton} {
		display: flex;
		align-items: flex-end;
		justify-content: center;
	}

	@media screen and (max-width: 860px) {
		padding: 10px 18px;
		font-size: 14px;
		line-height: 16px;
	}

	b {
		font-weight: bold;
	}
`;

const StyledDraftBody = styled.div`
	border-right: 1px solid #d8d8d8;
`;

export const HomeSidebar: React.FC = observer(() => {
	const {
		gameManageController: {lineup, totalPoints, isPlayerPickSame},
		lockoutController: {isLockout},
		tradeAlerts,
		I18n,
		confirmationManager,
		authController,
	} = useStore();
	const isDesktop = useMediaQuery("(min-width: 600px)");

	const handlePrompt = (location: H.Location, action: H.Action) => {
		if (!isPlayerPickSame && !confirmationManager.isForceRedirect) {
			confirmationManager.openModal(location.pathname);
			return false;
		}
		return true;
	};

	useEffect(() => {
		void tradeAlerts.request();
	}, [tradeAlerts]);

	const showTour = useCallback(() => {
		authController.showTour();
	}, [authController]);

	return (
		<StyledSidebar>
			<StyledSidebarHeader>
				<Exist when={!isLockout}>
					{I18n.t("draft_page.draft_picks")}
					<PureButton onClick={showTour}>
						<InfoIcon />
					</PureButton>
				</Exist>
				<Exist when={isLockout}>
					{I18n.t("draft_page.your_score")} <b>{totalPoints}</b>{" "}
					{I18n.t("draft_page.points")}
				</Exist>
				<Exist when={!isDesktop}>
					<BroadCasters />
				</Exist>
			</StyledSidebarHeader>
			<StyledDraftBody>
				{lineup.map((draftItem) => (
					<DraftItem
						key={draftItem.orderID}
						{...draftItem}
						isTradeAlert={!!tradeAlerts.data[draftItem.orderID]}
						tradeAlertMessage={tradeAlerts.data[draftItem.orderID]}
					/>
				))}
				<DraftsCounter />
			</StyledDraftBody>
			<Prompt message={handlePrompt} />
		</StyledSidebar>
	);
});
