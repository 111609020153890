import React from "react";
import styled from "styled-components";
import {MAX_LINEUP_SIZE} from "modules/constant";

const SliderWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-start;
	background: rgba(27, 72, 224, 0.1);
	height: 10px;
	border-radius: 14px;
`;

interface ISliderProps {
	picked: number;
}

const SliderProgress = styled.div<ISliderProps>`
	width: ${({picked}: ISliderProps) => (100 / MAX_LINEUP_SIZE) * picked}%;
	max-width: 100%;
	background: #1b48e0;
	border-radius: 14px;
	min-width: 10px;
	transition: width 0.3s;
`;

export const DraftsCounterSlider: React.FC<ISliderProps> = ({picked}) => (
	<SliderWrapper>
		<SliderProgress picked={picked} />
	</SliderWrapper>
);
